<template>
  <ItemPage
    title="Tipo de tags - Tags"
    size="sm"
    :actions="actions"
  >
    <v-form
      ref="form"
    >
      <v-text-field
        v-model="tagtype.name"
        :rules="rulesName"
        :disabled="!tagTypesUpdate"
        label="Nome"
        @input="checkUpdate"
      />
      <br>
      <template v-if="tagsView">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquise pelo nome da tag"
          single-line
          hide-details
        />
      </template>
    </v-form>
    <v-simple-table
      v-if="tagsView"
      fixed-header
    >
      <template v-if="loading">
        <Load :loadingmsg="loadingMsg" />
      </template>
      <div
        v-else
        class="mr-5 ml-5"
      >
        <v-data-table
          :headers="headers"
          :items="tags"
          :items-per-page="10"
          :search="search"
          item-key="id"
          @click:row="editTag($event)"
        />
      </div>
    </v-simple-table>
    <v-dialog
      v-model="dialogAddTag"
      width="500"
      persistent
    >
      <v-card>
        <template v-if="request">
          <Load :loadingmsg="loadingMsg" />
        </template>
        <template v-else>
          <AddTopic
            :key="renderComponent"
            title="Tag"
            @close="dialogAddTag = false"
            @save="newTag($event)"
          />
        </template>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogConfirm"
      width="500"
      persistent
    >
      <v-card>
        <template v-if="request">
          <Load :loadingmsg="loadingMsg" />
        </template>
        <template v-else>
          <DialogConfirm
            :key="renderComponent"
            title="o Tipo de tag"
            :name="tagtype.name"
            @close="dialogConfirm = false"
            @confirm="deleteTagType($event)"
          />
        </template>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
    >
      {{ msgSnackbar }}

      <template #action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </ItemPage>
</template>

<script>
import Load from '@/components/Loading.vue'
import AddTopic from '@/components/settings/Add_Tag_Type.vue'
import DialogConfirm from '@/components/Dialog_confirm.vue'
import ItemPage from '@/components/pages/ItemPage.vue'
import utils from '@/api/utils.js'

export default {
  components: {
    Load,
    AddTopic,
    ItemPage,
    DialogConfirm
  },
  data () {
    return {
      tagsView: false,
      tagsCreate: false,
      tagTypesUpdate: false,
      tagTypesDelete: false,
      dialogConfirm: false,
      btnDisable: true,
      renderComponent: 0,
      rulesName: [
        value => !!value || 'Preenchimento obrigatório.',
        value => (value && value.length >= 3) || 'Min 3 caracteres'
      ],
      dialogAddTag: false,
      loading: false,
      loadingSave: false,
      update: false,
      request: false,
      snackbar: false,
      msgSnackbar: '',
      timeout: 3000,
      currentTagtype: {},
      tagtype: {},
      tags: [],
      formError: [],
      search: '',
      headers: [
        {
          text: 'id',
          align: 'start',
          value: 'id'
        },
        {
          text: 'Nome da Tag',
          align: 'start',
          value: 'name'
        }
      ]
    }
  },
  computed: {
    id () {
      return this.$route.params.id
    },

    actions () {
      return [
        {
          label: 'Adicionar Tag',
          action: this.openDialogAddTag,
          icon: 'mdi-plus',
          disabled: !this.tagsCreate
        },
        {
          label: 'Atualizar',
          action: this.updateTagType,
          icon: 'mdi-floppy',
          disabled: this.btnDisable
        },
        {
          label: 'Deletar',
          action: () => { this.dialogConfirm = true },
          icon: 'mdi-delete',
          disabled: !this.tagTypesDelete
        }
      ]
    }
  },
  created () {
    this.fetchAll()
  },
  methods: {
    async fetchAll () {
      await this.getTagType()
      this.tagsView = utils.checkPermissions('tags.view-any')
      this.tagsCreate = utils.checkPermissions('tags.create')
      this.tagTypesUpdate = utils.checkPermissions('tag-types.update-any')
      this.tagTypesDelete = utils.checkPermissions('tag-types.delete')
    },
    async getTagType () {
      this.loading = true
      this.loadingMsg = 'Carregando lista de tags...'
      try {
        const response = await this.$api.getTagType(this.id)
        this.tagtype = response
        this.currentTagtype = utils.currentData(response)
        this.tags = response.tags
      } catch {
        // console.log('Erro ao obter tags')
      }
      this.loading = false
    },
    editTag (event) {
      this.$router.push({ name: 'tags.id', params: { id: event.id } })
    },
    openDialogAddTag () {
      this.renderComponent += 1
      this.dialogAddTag = true
    },
    sizes (data) {
      return utils.size(data)
    },
    checkUpdate () {
      const data = utils.newData(this.tagtype, this.currentTagtype)
      if (utils.size(data) > 0) {
        this.btnDisable = false
      } else {
        this.btnDisable = true
      }
      return data
    },
    checkDelete () {
      this.renderComponent += 1
      this.dialogConfirm = true
    },
    async deleteTagType () {
      this.request = true
      this.loadingMsg = 'Excluindo...'
      try {
        await this.$api.deleteTagType(this.id)
        this.request = false
        this.snackbar = true
        this.msgSnackbar = 'Excluido com sucesso'
        this.$router.back()
      } catch (e) {
        this.request = false
        this.snackbar = true
        this.msgSnackbar = 'Não foi possível Excluir, tente novamente mais tarde'
        // console.log(e)
      }
      this.request = false
    },
    async updateTagType () {
      const validate = await this.validate()
      if (validate === false) {
        return false
      }
      this.request = true
      const data = this.checkUpdate()
      try {
        await this.$api.updateTagType(this.id, data)
        this.request = false
        this.snackbar = true
        this.msgSnackbar = 'Atualizado com sucesso'
        this.fetchAll()
      } catch (e) {
        this.request = false
        this.snackbar = true
        this.msgSnackbar = 'Não foi possível atualizar, tente novamente mais tarde'
        // console.log(e)
      }
      this.request = false
    },
    validate () {
      return this.$refs.form.validate()
    },
    async newTag (data) {
      this.request = true
      this.loadingMsg = 'Salvando...'
      data.tag_type_id = await this.id
      try {
        await this.$api.newTag(data)
        this.request = false
        this.dialogAddTag = false
        this.snackbar = true
        this.msgSnackbar = 'Salvo com sucesso'
        this.fetchAll()
      } catch (e) {
        this.snackbar = true
        this.msgSnackbar = 'Não foi possível salvar, tente novamente mais tarde'
        // console.log(e)
      }
      this.request = false
    }
  }
}
</script>
