export const types = {
  VeryEasy: 1,
  Easy: 2,
  Medium: 3,
  Hard: 4,
  VeryHard: 5
}

export const displayNames = {
  [types.VeryEasy]: 'Muito Fácil',
  [types.Easy]: 'Fácil',
  [types.Medium]: 'Moderada',
  [types.Hard]: 'Difícil',
  [types.VeryHard]: 'Muito Difícil'
}

export function getTextForDisplay (questionDifficulty) {
  const name = displayNames[questionDifficulty]

  if (name === undefined) {
    return ''
  }

  return name
}

export default {
  types,
  displayNames,
  getTextForDisplay
}
