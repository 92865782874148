<template>
  <ItemPage
    title="Cadastro da Escola"
    size="md"
    :actions="actions"
  >
    <div class="white">
      <v-tabs
        v-model="tab"
        fixed-tabs
        show-arrows
        icons-and-text
      >
        <v-tabs-slider />

        <v-tab href="#dados">
          Dados da escola
          <v-icon>mdi-account-box</v-icon>
        </v-tab>

        <v-tab href="#maintainers">
          Mantenedores
          <v-icon>mdi-account-multiple</v-icon>
        </v-tab>

        <v-tab href="#contratos">
          Contratos
          <v-icon>mdi-file-document-edit</v-icon>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item id="dados">
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="school.name"
                  label="Nome"
                  @input="verifyUpdate()"
                />
                <v-select
                  v-model="school.is_defaulting"
                  :items="itens"
                  item-text="name"
                  item-value="value"
                  label="Financeiro"
                  @input="verifyUpdate()"
                />
                <v-text-field
                  v-model="school.code"
                  label="Código"
                  @input="verifyUpdate()"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item id="maintainers">
          <v-container>
            <v-row>
              <v-col>
                <div
                  v-for="userMaintainer in school.maintainers"
                  :key="userMaintainer.id"
                  class="mb-10"
                >
                  <ShowMaintainer
                    v-if="institutionsUpdate"
                    :maintainer="userMaintainer"
                  />
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item id="contratos">
          <v-container>
            <template v-if="!selectedContract">
              <v-row>
                <v-col>
                  <NewContract
                    :id="school.id"
                    @saveNewContract="goToContract($event)"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <ContractList
                    :idschool="school.id"
                    @gradeLevelGroups="setGradeLevelGroups($event)"
                    @teachingSystems="setTeachingSystems($event)"
                    @goTo="goToContract($event)"
                  />
                </v-col>
              </v-row>
            </template>
            <v-row v-else>
              <v-col>
                <v-btn
                  text
                  color="primary"
                  @click="selectedContract = false"
                >
                  <v-icon left>
                    mdi-arrow-left
                  </v-icon>
                  Escolher outro contrato
                </v-btn>
                <ShowContract
                  :contract="contract"
                  :systems="teachingSystems"
                  :gradelevelgroups="gradelevelGroups"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <v-dialog
      v-model="loading"
      width="500"
      persistent
    >
      <Load
        :loadingmsg="loadingMsg"
      />
    </v-dialog>
  </ItemPage>
</template>

<script>
import Load from '@/components/Loading.vue'
import NewContract from '@/components/schools/contracts/New_Contract.vue'
import ContractList from '@/components/schools/contracts/List_of_Contracts.vue'
import ShowContract from '@/components/schools/contracts/Contract.vue'
import ShowMaintainer from '@/components/schools/Maintainer.vue'
import ItemPage from '@/components/pages/ItemPage.vue'
import utils from '@/api/utils.js'
import { getTextForDisplay as getStatusName, listTypes } from '@/enums/InstitutionStatusType.js'
import AddMaintainerDialog from '@/components/AddMaintainerDialog.vue'

export default {
  components: {
    Load,
    NewContract,
    ContractList,
    ShowMaintainer,
    ShowContract,
    ItemPage
  },
  data () {
    return {
      institutionsUpdate: false,
      institutionsDelete: false,
      tab: 'dados',
      school: [],
      currentSchool: [],
      gradelevelGroups: [],
      teachingSystems: [],
      contracts: [],
      contract: {},
      itens: [],
      selectedContract: false,
      loading: false,
      loadingMsg: '',
      maintainer: [],
      disableUpdate: false
    }
  },
  computed: {
    id () {
      return this.$route.params.id
    },
    actions () {
      if (this.tab === 'maintainers') {
        return [
          {
            label: 'Adicionar Mantenedor',
            action: this.addMaintainer,
            icon: 'mdi-floppy',
            disabled: !this.institutionsUpdate
          }
        ]
      }
      if (this.tab === 'contratos') {
        return []
      }
      return [
        {
          label: 'Salvar Alterações',
          action: this.updateSchool,
          icon: 'mdi-floppy',
          disabled: !this.disableUpdate
        },
        {
          label: 'Deletar',
          action: this.deleteInstitution,
          icon: 'mdi-delete',
          disabled: !this.institutionsDelete
        }
      ]
    }
  },
  async created () {
    await this.fetchAll()
  },
  methods: {
    async fetchAll () {
      this.institutionsUpdate = utils.checkPermissions('institutions.update-any')
      this.institutionsDelete = utils.checkPermissions('institutions.delete')
      this.itens = this.listTypes()
      await this.getSchoolId()
    },
    async getSchoolId () {
      this.loading = true
      this.loadingMsg = 'Carregando informações'
      try {
        const getSchool = await this.$api.getSchoolId(this.id)
        this.school = getSchool
        this.currentSchool = utils.currentData(getSchool)
      } catch (e) {
        this.loading = false
        this.getErrors(e.response)
      }
      this.loading = false
    },

    verifyUpdate () {
      const update = utils.newData(this.school, this.currentSchool)
      if (Object.keys(update).length > 0) {
        this.disableUpdate = true
      } else {
        this.disableUpdate = false
      }
    },

    async updateSchool () {
      this.loading = true
      this.loadingMsg = 'Salvando informações'
      const data = utils.newData(this.school, this.currentSchool)
      try {
        await this.$api.updateSchool(data, this.id)
        this.getSchoolId()
        this.disableUpdate = false
      } catch {
        // console.log('Erro ao salvar informações')
      }
    },
    setGradeLevelGroups (data) {
      this.gradelevelGroups = data
    },
    setTeachingSystems (data) {
      this.teachingSystems = data
    },

    goToContract (contract) {
      this.contract = contract
      this.selectedContract = true
    },
    async deleteInstitution () {
      this.$dialog.confirm('Apagar instituição?')
        .text('Essa ação não pode ser desfeita')
        .label('Apagar')
        .onConfirm(async () => {
          try {
            await this.$api.deleteSchool(this.id)
            this.$router.push({ name: 'escolas' })
            this.$store.dispatch('setSnackBar', { text: 'Instituição apagada com sucesso.' })
          } catch (e) {
            this.getErrors(e.response)
          }
        })
        .show()
    },

    async addMaintainer () {
      this.$dialog.custom(AddMaintainerDialog).show()
        .then(async (maintainer) => {
          if (!maintainer) {
            return
          }
          this.loading = true
          this.loadingMsg = 'adicionando mantenedor'
          try {
            await this.$api.saveMaintainers(this.id, maintainer)
            this.getSchoolId()
            this.disableUpdate = true
          } catch {
            // console.log('Erro ao salvar informações')
          }
          this.loading = false
        })
    },

    listTypes,
    getStatusName
  }
}
</script>
