<template>
  <ItemPage
    :title="`Editando questão #${this.$route.params.id}`"
    size="md"
    :actions="actions"
  >
    <div class="white">
      <div class="title">
        Enunciado
      </div>
      <div class="editor elevation-2 mt-3 white">
        <editor-menu-bar
          v-slot="{ commands, isActive, getNodeAttrs }"
          :editor="statementEditor"
        >
          <v-toolbar
            flat
            dense
          >
            <v-btn
              icon
              tile
              :input-value="isActive.bold()"
              :color="isActive.bold() ? 'primary' : ''"
              @click="commands.bold()"
            >
              <v-icon>
                mdi-format-bold
              </v-icon>
            </v-btn>

            <v-btn
              icon
              tile
              :input-value="isActive.italic()"
              :color="isActive.italic() ? 'primary' : ''"
              @click="commands.italic()"
            >
              <v-icon>
                mdi-format-italic
              </v-icon>
            </v-btn>

            <v-btn
              icon
              tile
              :input-value="isActive.aude_question_image()"
              :color="isActive.aude_question_image() ? 'primary' : ''"
              @click="handleImageCommand(commands.aude_question_image)"
            >
              <v-icon>
                mdi-image
              </v-icon>
            </v-btn>

            <v-btn
              icon
              tile
              :input-value="isActive.aude_question_katex()"
              :color="isActive.aude_question_katex() ? 'primary' : ''"
              @click="handleKatexCommand(commands, isActive.aude_question_katex(), getNodeAttrs)"
            >
              <v-icon>
                mdi-square-root
              </v-icon>
            </v-btn>
          </v-toolbar>
        </editor-menu-bar>
        <v-divider />
        <div>
          <editor-content
            class="editor-content pa-5"
            :editor="statementEditor"
          />
        </div>
      </div>

      <div class="mt-12 d-flex align-center">
        <div class="title">
          Respostas
        </div>

        <v-spacer />

        <v-btn
          color="primary"
          text
          @click="addAnswer"
        >
          <v-icon left>
            mdi-plus
          </v-icon>
          Adicionar resposta
        </v-btn>
      </div>

      <template v-if="answers.length > 0">
        <div
          v-for="(answer, index) in answers"
          :key="answer.id"
          class="mt-8"
        >
          <div class="d-flex justify-space-between">
            <div>Resposta {{ index + 1 }}</div>
            <v-btn
              v-if="correctAnswerId !== answer.id"
              class="text-decoration-none"
              text
              @click="setCorrectAnswer(answer.id)"
            >
              Marcar como correta
            </v-btn>

            <div
              v-else
              class="success--text"
              style="min-height: 36px;"
            >
              <v-icon color="success">
                mdi-check
              </v-icon>
              Correta
            </div>
          </div>
          <div class="mt-2 editor elevation-2 white">
            <editor-menu-bar
              v-slot="{ commands, isActive, getNodeAttrs }"
              :editor="answer.editor"
            >
              <v-toolbar
                flat
                dense
              >
                <v-btn
                  icon
                  tile
                  :input-value="isActive.bold()"
                  :color="isActive.bold() ? 'primary' : ''"
                  @click="commands.bold()"
                >
                  <v-icon>
                    mdi-format-bold
                  </v-icon>
                </v-btn>

                <v-btn
                  icon
                  tile
                  :input-value="isActive.italic()"
                  :color="isActive.italic() ? 'primary' : ''"
                  @click="commands.italic()"
                >
                  <v-icon>
                    mdi-format-italic
                  </v-icon>
                </v-btn>

                <v-btn
                  icon
                  tile
                  :input-value="isActive.aude_question_image()"
                  :color="isActive.aude_question_image() ? 'primary' : ''"
                  @click="handleImageCommand(commands.aude_question_image)"
                >
                  <v-icon>
                    mdi-image
                  </v-icon>
                </v-btn>

                <v-btn
                  icon
                  tile
                  :input-value="isActive.aude_question_katex()"
                  :color="isActive.aude_question_katex() ? 'primary' : ''"
                  @click="handleKatexCommand(commands, isActive.aude_question_katex(), getNodeAttrs)"
                >
                  <v-icon>
                    mdi-square-root
                  </v-icon>
                </v-btn>

                <v-spacer />

                <v-btn
                  icon
                  tile
                  @click="showRemoveAnswerModal(index)"
                >
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </v-toolbar>
            </editor-menu-bar>
            <v-divider />
            <div>
              <editor-content
                class="editor-content pa-5"
                :editor="answer.editor"
              />
            </div>
          </div>
        </div>
      </template>
      <div
        v-else
        class="grey--text text-center my-5"
      >
        Nenhuma reposta.
      </div>
    </div>

    <v-dialog
      v-model="shouldShowImageUploadModal"
      width="500"
    >
      <v-card :disabled="busy">
        <v-card-title>
          Adicionar Imagem
        </v-card-title>
        <v-card-text>
          <v-file-input
            ref="inputImageRef"
            v-model="selectedImage"
            filled
            accept="image/*"
            label="Imagem"
          />

          <v-select
            v-model="imageSize"
            :items="imageSizes"
            label="Tamanho de exibição"
          />
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            text
            color="primary"
            @click="onUploadImageClick"
          >
            Enviar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="shouldShowKatexModal"
      width="500"
    >
      <v-card :disabled="busy">
        <v-card-title>
          {{ katexUpdating ? 'Atualizar Fórmula' : 'Adicionar Fórmula' }}
        </v-card-title>
        <v-card-text>
          <div>
            <p>
              Use um editor para criar a fórmula e cole a expressão LaTeX no campo abaixo.
              Recomendamos <a
                href="https://www.codecogs.com/latex/eqneditor.php?lang=pt-br"
                target="_blank"
              >CodeCogs</a>
              ou <a
                href="https://www.latex4technics.com/"
                target="_blank"
              >Latex4Technics</a>.
            </p>
            <p>
              <a
                class="font-weight-bold"
                @click="insertSampleExpression"
              >Inserir fórmula de exemplo</a>
            </p>
          </div>

          <v-textarea
            v-model="katexInput"
            label="Expressão LaTeX"
            style="font-family: monospace;"
          />

          <div v-if="!invalidKatex">
            <div>Previsualização:</div>
            <!--  eslint-disable -->
            <div
              class="black--text mt-3"
              v-html="katexPreview"
            />
          </div>
            <div v-else class="red--text">
              Insira uma expressão LaTeX válida.
            </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text color="primary" :disabled="invalidKatex" @click="onSaveKatexClick">
            {{ katexUpdating ? 'Atualizar' : 'Adicionar' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="shouldShowRemoveAnswerModal" width="500">
      <v-card>
        <v-card-title>
          Remover resposta
        </v-card-title>
        <v-card-text>
          Tem certeza que deseja remover esta resposta?
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text color="primary" @click="shouldShowRemoveAnswerModal = false">
            Manter resposta
          </v-btn>
          <v-btn text color="error" @click="removeAnswer(answerToRemoveIndex)">
            Sim, remover.
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </ItemPage>
</template>

<script>
import ItemPage from '@/components/pages/ItemPage.vue'
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import {
  HardBreak,
  Bold,
  Italic,
  History
} from 'tiptap-extensions'

import Image from '@/components/contents/questions/editor/nodes/Image'
import Katex from '@/components/contents/questions/editor/nodes/Katex'
import { makeid } from '../../../../../util'
import katex from 'katex'

export default {
  components: {
    EditorContent,
    EditorMenuBar,
    ItemPage
  },

  data: () => ({
    question: null,
    statementEditor: new Editor({
      extensions: [
        new HardBreak(),
        new Image(),
        new Bold(),
        new Italic(),
        new History(),
        new Katex()
      ],
      content: {
        type: 'doc',
        content: [
          {
            type: 'paragraph'
          }
        ]
      }
    }),
    imageSizes: [
      {
        text: 'Pequeno',
        value: 1
      }, {
        text: 'Médio',
        value: 2
      }, {
        text: 'Grande',
        value: 3
      }
    ],
    imageSize: 2,
    answers: [],
    shouldShowImageUploadModal: false,
    imageCommand: null,

    // Katex extension
    shouldShowKatexModal: false,
    katexCommand: null,
    katexInput: null,
    katexUpdating: false,
    invalidKatex: true,
    katexPreview: '',

    shouldShowRemoveAnswerModal: false,
    selectedImage: null,
    isFetching: true,
    correctAnswerId: null,
    busy: false
  }),
  computed: {
    actions () {
      return [
        {
          label: this.question?.is_draft ? 'Publicar' : 'Salvar',
          action: this.submit,
          icon: 'mdi-floppy',
          disabled: this.busy
        }
      ]
    }
  },
  watch: {
    katexInput (value) {
      if (value === '') {
        this.invalidKatex = true
        return
      }

      try {
        this.katexPreview = katex.renderToString(value)
        this.invalidKatex = false
      } catch (e) {
        this.invalidKatex = true
      }
    }
  },

  async mounted () {
    if (!this.$route.params.id) {
      return
    }

    this.busy = true

    const res = await this.$api.getQuestion(this.$route.params.id)
    this.question = res

    const textData = JSON.parse(res.text_data)

    this.statementEditor.setContent(textData.statement, true)

    this.correctAnswerId = textData.correctAnswerId

    for (const answer of textData.answers) {
      answer.editor = new Editor({
        extensions: [
          new HardBreak(),
          new Image(),
          new Bold(),
          new Italic(),
          new History(),
          new Katex()
        ],
        content: answer.state
      })

      answer.isCorrect = textData.correnctAnswerId && (textData.correnctAnswerId === answer.id)
      delete answer.state

      if (!answer.id) {
        answer.id = makeid()
      }

      this.answers.push(answer)
    }

    this.busy = false
  },

  beforeDestroy () {
    this.statementEditor.destroy()
    for (const answer of this.answers) {
      answer.editor.destroy()
    }
  },

  methods: {
    // Image Extension
    handleImageCommand (command) {
      this.shouldShowImageUploadModal = true
      this.imageCommand = command
    },

    async onUploadImageClick () {
      this.busy = true
      try {
        await this.uploadImage()
      } catch (e) {
        alert('Ocorreu um erro ao enviar a imagem')
        this.busy = false
      }
      this.shouldShowImageUploadModal = false
      this.busy = false
    },

    async uploadImage () {
      const formData = new FormData()
      formData.append('image', this.selectedImage)

      const uploadedImageData = await this.$api.uploadQuestionImage(this.$route.params.id, formData, this.imageSize)

      this.imageCommand({
        src: uploadedImageData.url,
        audeQuestionImageId: uploadedImageData.id
      })
    },

    // Katex Extension
    handleKatexCommand (commands, isActive, getNodeAttrs) {
      if (!isActive) {
        this.katexUpdating = false
        this.katexInput = ''
        this.katexCommand = commands.aude_question_katex_createNode
      } else {
        this.katexUpdating = true
        const { expression } = getNodeAttrs('aude_question_katex')
        this.katexInput = expression
        this.katexCommand = commands.aude_question_katex_updateNode
      }

      this.shouldShowKatexModal = true
    },

    onSaveKatexClick () {
      this.katexCommand({
        expression: this.katexInput
      })

      this.shouldShowKatexModal = false
    },

    insertSampleExpression () {
      this.katexInput = 'x=\\frac{-b\\pm\\sqrt{b^2-4ac}}{2a}'
    },

    addAnswer () {
      const editor = new Editor({
        extensions: [
          new HardBreak(),
          new Image(),
          new Bold(),
          new Italic(),
          new History(),
          new Katex()
        ],
        content: {
          type: 'doc',
          content: [
            {
              type: 'paragraph'
            }
          ]
        }
      })

      this.answers.push(
        {
          id: makeid(),
          isCorrect: false,
          editor
        })
    },

    showRemoveAnswerModal (index) {
      this.answerToRemoveIndex = index
      this.shouldShowRemoveAnswerModal = true
    },

    removeAnswer (index) {
      this.answers[index].editor.destroy()
      this.answers.splice(index, 1)
      this.shouldShowRemoveAnswerModal = false
    },

    isCorrect (id) {
      return this.correctAnswerId === id
    },

    setCorrectAnswer (id) {
      this.correctAnswerId = id
    },

    async submit () {
      if (!this.answers.length) {
        alert('Adicione ao menos uma resposta.')
        return
      }

      if (!this.correctAnswerId) {
        alert('Marque a resposta correta.')
        return
      }

      const imageIds = []

      this.statementEditor.state.doc.descendants(function (node) {
        if (node.type.name === 'aude_question_image') {
          imageIds.push(node.attrs.audeQuestionImageId)
        }
      })

      for (const answer of this.answers) {
        answer.editor.state.doc.descendants(function (node) {
          if (node.type.name === 'aude_question_image') {
            imageIds.push(node.attrs.audeQuestionImageId)
          }
        })
      }

      const textData = {
        statement: this.statementEditor.getJSON(),
        answers: [],
        correctAnswerId: this.correctAnswerId
      }

      for (const answer of this.answers) {
        textData.answers.push({
          id: answer.id,
          state: answer.editor.getJSON()
        })
      }

      await this.$api.updateQuestion(this.$route.params.id, {
        text_data: JSON.stringify(textData),
        images_ids: imageIds
      })

      if (this.question.is_draft) {
        alert('Questão publicada com sucesso.')
      } else {
        alert('Questão atualizada com sucesso.')
      }

      this.$router.push({ name: 'conteudos.questoes.id', params: { id: this.question.id } })
    }
  }
}
</script>
