<template>
  <ItemPage
    :title="teachingSystem.name ? teachingSystem.name : '' "
    size="md"
  >
    <v-simple-table
      v-if="gradeLevelGroupsView"
      fixed-header
    >
      <template v-if="loading">
        <Load :loadingmsg="loadingMsg" />
      </template>
      <v-data-table
        v-else
        :headers="headers"
        :items="gradeLevelGroups"
        hide-default-footer
        item-key="id"
      >
        <template #item.name="{ item }">
          <router-link :to="{ name: 'conteudos-v2.sistema-de-ensino.teachingSystemID.segmento.gradeLevelGroupID.ano', params: { gradeLevelGroupID: item.id } }">
            {{ item.name }}
          </router-link>
        </template>
      </v-data-table>
    </v-simple-table>
    <template v-else>
      usuário sem permissão
    </template>
  </ItemPage>
</template>

<script>
import Load from '@/components/Loading.vue'
import ItemPage from '@/components/pages/ItemPage.vue'
import utils from '@/api/utils.js'

export default {
  components: {
    Load,
    ItemPage
  },
  data () {
    return {
      gradeLevelGroupsView: false,
      loading: true,
      loadingSave: false,
      teachingSystem: {},
      gradeLevelGroups: [],
      headers: [
        {
          text: 'id',
          align: 'start',
          value: 'id'
        },
        {
          text: 'Nome',
          align: 'start',
          value: 'name'
        },
        {
          text: 'Abreviatura',
          align: 'start',
          value: 'short_name'
        }
      ]
    }
  },
  computed: {
    teachingSystemID () {
      return this.$route.params.teachingSystemID
    }
  },
  created () {
    this.fetchAll()
  },
  methods: {
    async fetchAll () {
      this.gradeLevelGroupsView = utils.checkPermissions('grade-levels.view-any')
      await this.getGradeLevelGroup()
      await this.getTeachingSystemID()
    },
    async getGradeLevelGroup () {
      this.loading = true
      this.loadingMsg = 'Carregando lista dos seguimentos...'
      try {
        this.gradeLevelGroups = await this.$api.getGradeLevelGroupsOfTeachingSystem(this.teachingSystemID)
      } catch {
        // console.log('Erro ao obter os seguimentos')
      }
      this.loading = false
    },
    async getTeachingSystemID () {
      this.loading = true
      this.loadingMsg = 'Carregando Lista...'
      try {
        this.teachingSystem = await this.$api.getTeachingSystemID(this.teachingSystemID)
      } catch {
        // console.log('Erro ao obter o sistemas de ensino')
      }
      this.loading = false
    },
    editGradeLevelGroup (event) {
      this.$router.push({ name: 'conteudos-v2.sistema-de-ensino.teachingSystemID.segmento.gradeLevelGroupID.ano', params: { gradeLevelGroupID: event.id } })
    },
    validate () {
      return this.$refs.form.validate()
    }
  }
}
</script>
