<template>
  <div>
    <v-container
      class="fill-height"
      fluid
    >
      <v-row
        justify="center"
      >
        <v-col
          cols="12"
          sm="12"
          md="8"
          lg="8"
        >
          <Login />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import Login from '@/pages/Login.vue'

export default {
  layout: 'auth',
  components: {
    Login
  },
  data: () => ({
    //
  }),
  methods: {
  }
}
</script>
