<template>
  <div class="row">
    <div class="col-sm-6 col-md-4 d-flex align-center">
      <v-select
        v-model="parameters.teaching_system_id"
        :items="systems"
        item-text="name"
        item-value="id"
        label="Sistema de ensino"
      >
        <template
          v-if="parameters.teaching_system_id !== null"
          #prepend
        >
          <v-btn
            color="primary"
            text
            @click="parameters.teaching_system_id = null"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-select>
    </div>

    <div class="col-sm-6 col-md-4 d-flex align-center">
      <v-select
        v-model="parameters.grade_level_id"
        :items="gradelevelGroups"
        item-text="name"
        item-value="id"
        :disabled="!Boolean(parameters.teaching_system_id)"
        label="Segmentos"
      >
        <template
          v-if="parameters.grade_level_id !== null"
          #prepend
        >
          <v-btn
            color="primary"
            text
            @click="parameters.grade_level_id = null"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-select>
    </div>

    <div class="col-sm-6 col-md-4 d-flex align-center">
      <v-select
        v-model="parameters.volume_id"
        :items="volumes"
        item-text="name"
        item-value="id"
        :disabled="parameters.grade_level_id !== null && parameters.teaching_system_id !== null ? false : true"
        label="Volume"
      >
        <template
          v-if="parameters.volume_id !== null"
          #prepend
        >
          <v-btn
            color="primary"
            text
            @click="parameters.volume_id = null"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-select>
    </div>

    <div class="col-sm-6 col-md-4 d-flex align-center">
      <v-select
        v-model="parameters.subject_id"
        :items="subjects"
        item-text="name"
        item-value="id"
        :disabled="parameters.grade_level_id !== null ? false : true"
        label="Matéria"
      >
        <template
          v-if="parameters.subject_id !== null"
          #prepend
        >
          <v-btn
            color="primary"
            text
            @click="parameters.subject_id = null"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-select>
    </div>

    <div class="col-sm-6 col-md-4 d-flex align-center">
      <v-select
        v-model="parameters.topic_id"
        :items="topics"
        item-text="name"
        item-value="id"
        :disabled="parameters.subject_id !== null ? false : true"
        label="Tópico"
      >
        <template
          v-if="parameters.topic_id !== null"
          #prepend
        >
          <v-btn
            color="primary"
            text
            @click="parameters.topic_id = null"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-select>
    </div>

    <div class="col-sm-6 col-md-4 d-flex align-center">
      <v-select
        v-model="parameters.type"
        :items="types"
        item-text="typeName"
        item-value="value"
        label="Tipo"
      >
        <template
          v-if="parameters.type !== null"
          #prepend
        >
          <v-btn
            color="primary"
            text
            @click="parameters.type = null"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-select>
    </div>
  </div>
</template>

<script>
import utils from '@/api/utils.js'
import { listTypes } from '@/enums/materialTypes.js'

export default {
  components: {
  },

  data: () => ({
    systems: [],
    gradelevelGroups: [],
    gradelevelGroupSelected: null,
    gradelevels: [],
    volumes: [],
    subjects: [],
    topics: [],
    types: [],
    parameters: {
      teaching_system_id: null,
      grade_level_id: null,
      volume_id: null,
      subject_id: null,
      topic_id: null,
      type: null
    }
  }),
  watch: {
    async 'parameters.teaching_system_id' () {
      if (this.parameters.grade_level_id !== null && this.parameters.teaching_system_id !== null) {
        await this.getVolumes()
      }
      if (this.parameters.teaching_system_id === null) {
        this.volumes = []
        this.parameters.volume_id = null
      }
      this.setParameters()
    },
    async 'parameters.grade_level_id' () {
      this.volumes = []
      this.parameters.volume_id = null
      this.parameters.subject_id = null
      if (this.parameters.grade_level_id !== null && this.parameters.teaching_system_id !== null) {
        await this.getVolumes()
      }
      await this.getSubjects()
      this.setParameters()
    },
    'parameters.volume_id' () {
      this.setParameters()
    },
    async 'parameters.subject_id' () {
      this.parameters.topic_id = null
      await this.getTopics()
      this.setParameters()
    },
    'parameters.topic_id' () {
      this.setParameters()
    },
    'parameters.type' () {
      this.setParameters()
    }
  },

  async created () {
    await this.fetchAll()
    this.types = utils.contentType()
  },

  methods: {
    listTypes,

    async fetchAll () {
      this.types = listTypes()
      this.contentType = utils.contentType()
      await this.getTeachingSystems()
      await this.getGradeLevelGroups()
    },
    setParameters () {
      const query = this.buildQuery()
      this.$emit('setParameters', query)
    },
    buildQuery () {
      const queryItems = Object.entries(this.parameters).map((item) => {
        if (item[1] !== null) {
          const queryItem = '&' + item[0] + '=' + item[1]
          return queryItem
        }
      })
      const query = queryItems.join('')
      return query
    },
    async getTeachingSystems () {
      try {
        const response = await this.$api.getTeachingSystems()
        this.systems = response
      } catch (e) {
        // console.log(e.response)
      }
    },
    async getGradeLevelGroups () {
      try {
        const data = await this.$api.getGradeLevelGroups()
        const levels = data.map((item) => {
          return item.grade_levels.map((level) => {
            return {
              name: item.name + ' - ' + level.name,
              id: level.id
            }
          })
        })
        this.gradelevelGroups = [
          { id: null, name: 'Escolha um segmento e ano' },
          ...levels.flatMap((item) => item)
        ]
      } catch (e) {
        // console.log(e.response)
      }
    },
    async getSubjects () {
      try {
        const response = await this.$api.getSubjectsOfGradeLevel(this.parameters.grade_level_id, this.parameters.teaching_system_id)
        this.subjects = response
      } catch (e) {
        // console.log(e)
      }
    },
    async getTopics () {
      try {
        const response = await this.$api.getTopics(this.parameters.grade_level_id, this.parameters.subject_id, this.parameters.teaching_system_id, true)
        this.topics = response
      } catch (e) {
        // console.log(e)
      }
    },
    async getVolumes () {
      try {
        this.volumes = await this.$api.getVolumes(this.parameters.grade_level_id, this.parameters.teaching_system_id)
      } catch (e) {
        // console.log(e)
      }
    }
  }
}
</script>
