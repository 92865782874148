<template>
  <div>
    <v-progress-linear
      v-if="loading"
      color="amber"
      indeterminate
    />
    <div v-else>
      <div
        v-for="question in questions"
        :key="question.id"
      >
        <div class="grey lighten-5 my-8">
          <div class="grey lighten-3 px-5 py-3 rounded">
            <router-link :to="{ name: 'conteudos.avaliacao-aude.grupo-de-questoes.id.status.gradelevel.questao', params: { idgroup: idGroup, status: status, id: question.id } }">
              Questão #{{ question.id }}
            </router-link>
          </div>
          <div class="px-5">
            <editor-content
              v-if="question.editor"
              :editor="question.editor"
              class="editor-content mt-5"
            />
            <div
              v-else
              class="red--text"
            >
              Conteúdo corrompido! Apague esta questão e cadastre-a novamente.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import handlesServerValidation from '@/mixins/handles-server-validation.js'
import { Editor, EditorContent } from 'tiptap'
import {
  HardBreak,
  Bold,
  Italic,
  History
} from 'tiptap-extensions'
import Image from '@/components/contents/questions/editor/nodes/Image'
import Katex from '@/components/contents/questions/editor/nodes/Katex'
// import dayjs from '@/dayjs'

export default {
  components: {
    EditorContent
  },

  mixins: [
    handlesServerValidation
  ],

  props: {
    idGroup: { type: Number, default: null },
    status: { type: Number, default: null }
  },
  data: () => ({
    loading: true,

    // Questions
    questions: []
  }),

  async created () {
    if (this.idGroup) {
      await this.fetchPage()
    }
  },

  methods: {
    async fetchPage () {
      try {
        const data = await this.$api.ListAudeExamQuestions(this.idGroup)
        for (const question of data) {
          try {
            question.editor = new Editor({
              editable: false,
              extensions: [
                new HardBreak(),
                new Bold(),
                new Image(),
                new Italic(),
                new History(),
                new Katex()
              ],
              content: JSON.parse(question.text_data).statement
            })
          } catch (e) {
            question.editor = null
          }
        }

        if (this.questions.length > 0) {
          for (const question of this.questions) {
            question.editor && question.editor.destroy()
          }
        }

        this.questions = data
        // this.totalPages = data.meta.last_page

        this.loading = false
      } catch (e) {
        this.loading = false
        this.$store.dispatch('setSnackBar', { text: 'Ocorreu um erro ao tentar listar as questões.', color: 'red' })
        this.getErrors(e)
      }
    }
  }
}
</script>
