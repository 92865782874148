<template>
  <ItemPage
    title="Novo Material"
    size="sm"
    :actions="actions"
  >
    <div
      v-if="subjectTextBooksCreate"
      class="white"
    >
      <v-row
        align="center"
        no-gutters
      >
        <v-col
          cols="12"
          sm="6"
        >
          <div class="font-weight-bold">
            Capa do material
          </div>
        </v-col>
        <v-col
          class="ml-auto"
          cols="auto"
        >
          <UpCoverMaterial
            :key="renderUpCoverMaterial"
            @beginUpload="uploadMaterial"
            @upload="setCoverMaterialFile($event)"
            @uploadFail="waitingUpload = true"
          />
        </v-col>
      </v-row>
      <v-row
        class="mt-10 mb-10"
        align="center"
        no-gutters
      >
        <v-col
          cols="12"
          sm="6"
        >
          <div class="font-weight-bold">
            Material
          </div>
          <div class="text-body-2 text--secondary mt-1">
            Envie o material no formato (*.zip) e aguarde sua publicação.
          </div>
        </v-col>
        <v-col
          class="ml-auto"
          cols="auto"
        >
          <UpMaterial
            :key="renderUpMaterial"
            @beginUpload="uploadMaterial"
            @upload="setMaterialFile($event)"
            @uploadFail="waitingUpload = true"
          />
        </v-col>
      </v-row>
      <v-form
        ref="form"
        :disabled="waitEnable"
      >
        <v-row>
          <v-col
            cols="6"
            class="mr-auto"
          >
            <v-select
              v-model="teaching_system_id"
              :error-messages="errors.teaching_system_id"
              :items="systems"
              item-text="name"
              item-value="id"
              label="Sistemas de ensino"
              placeholder="Escolha o sistema de ensino"
              hide-details="auto"
              outlined
            />
          </v-col>
          <v-col
            cols="6"
            class="mr-auto"
          >
            <v-select
              v-model="publishing_year"
              :error-messages="errors.publishing_year"
              :items="years"
              label="Ano de publicação"
              placeholder="Escolha o ano de publicação"
              hide-details="auto"
              outlined
            />
          </v-col>
          <v-col
            cols="6"
            class="mr-auto"
          >
            <v-select
              v-model="grade_level_group"
              :error-messages="errors.grade_level_id"
              :items="gradelevelGroups"
              item-text="name"
              item-value="id"
              label="Segmento"
              placeholder="Escolha o segmento de ensino"
              hide-details="auto"
              outlined
              @change="getGradeLevel"
            />
          </v-col>
          <v-col
            cols="6"
            class="mr-auto"
          >
            <v-select
              v-model="grade_level_id"
              :error-messages="errors.grade_level_id"
              :items="gradelevels"
              item-text="name"
              item-value="id"
              label="Ano"
              placeholder="Escolha o ano de ensino"
              hide-details="auto"
              outlined
              @change="getSubjects"
            />
          </v-col>
          <v-col
            cols="12"
            class="mr-auto"
          >
            <v-select
              v-model="subject_id"
              :error-messages="errors.subject_id"
              :items="subjects"
              item-text="name"
              item-value="id"
              label="Matéria"
              placeholder="Escolha uma matéria"
              hide-details="auto"
              outlined
            />
          </v-col>
          <v-col
            v-if="enableName"
            cols="12"
            class="mr-auto"
          >
            <v-text-field
              v-model="name"
              :error-messages="errors.name"
              label="Nome do material"
              placeholder="Insira o titulo do material que será enviado"
              hide-details="auto"
              outlined
            />
          </v-col>
          <v-col
            cols="4"
            class="mr-auto"
          >
            <v-select
              v-model="volume"
              :error-messages="errors.volume_id"
              :items="volumes"
              item-text="name"
              item-value="id"
              label="Volume"
              outlined
            />
          </v-col>
          <v-col
            cols="4"
            class="mr-auto"
          >
            <v-text-field
              v-model="order"
              :error-messages="errors.order"
              label="Ordem"
              type="number"
              hide-details="auto"
              outlined
            />
            <span style="font-size: 12px; color: blue"> Preencher ordem com ( -1 ) para materiais paradidaticos</span>
          </v-col>
          <v-col
            cols="4"
            class="mr-auto"
          >
            <v-select
              v-model="type"
              :error-messages="errors.type"
              :items="types"
              item-text="name"
              item-value="id"
              label="Tipo"
              outlined
            />
          </v-col>
          <v-col
            cols="6"
            class="mr-auto"
          >
            Material do professor
            <v-switch
              v-model="is_teacher_material"
              class="ma-2"
              :label="is_teacher_material ? 'Sim' : 'Não'"
            />
          </v-col>
          <v-col
            cols="6"
            class="mr-auto"
          >
            Publicar material quando salvar
            <v-switch
              v-model="should_publish_after_processing"
              class="ma-2"
              :label="should_publish_after_processing ? 'Sim' : 'Não'"
            />
          </v-col>
        </v-row>
      </v-form>
    </div>
    <template v-else>
      Usuário sem permissão
    </template>
    <v-dialog
      id="modal-listOrNew"
      v-model="modalListOrNew"
      color="white"
      width="500"
      persistent
    >
      <v-card>
        <v-card-text>
          <v-card-title class="text-center">
            Deseja cadastrar um novo material ?
          </v-card-title>
          <v-card-actions class="d-flex justify-space-between">
            <v-btn
              class="d-flex align-center"
              text
              color="primary"
              @click="goToTextBookList"
            >
              Não
            </v-btn>
            <v-btn
              class="d-flex align-center"
              color="primary"
              @click="modalNewMaterial"
            >
              Sim
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
    >
      {{ msgSnackbar }}

      <template #action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </ItemPage>
</template>

<script>
import utils from '@/api/utils.js'
import UpMaterial from '@/components/settings/UploadMaterial.vue'
import UpCoverMaterial from '@/components/settings/UploadCoverMaterial.vue'
import ItemPage from '@/components/pages/ItemPage.vue'
import { listTypes } from '@/enums/materialTypes'
import { listTypes as listVolumeTypes } from '@/enums/volumeTypes'

export default {
  components: {
    UpMaterial,
    UpCoverMaterial,
    ItemPage
  },
  data () {
    return {
      waitEnable: true,
      enableName: false,
      subjectTextBooksCreate: false,
      loading: false,
      modalListOrNew: false,
      btnDisable: true,
      rulesName: [
        value => !!value || 'Preenchimento obrigatório.'
      ],
      snackbar: false,
      msgSnackbar: '',
      timeout: 3000,
      formError: {},

      name: '',
      years: [
        new Date().getFullYear() + 1,
        new Date().getFullYear(),
        new Date().getFullYear() - 1,
        new Date().getFullYear() - 2,
        new Date().getFullYear() - 3
      ],
      is_teacher_material: false,
      is_published: false,
      should_publish_after_processing: true,
      subject_id: null,
      subjects: [],
      teaching_system_id: null,
      systems: [],
      temporary_upload_file: null,
      cover_temporary_upload_file: null,
      publishing_year: null,
      grade_level_group: null,
      gradelevelGroups: [],
      grade_level_id: null,
      type: null,
      gradelevels: [],
      files: [],
      types: [],
      order: 0,
      volume: null,
      volumes: [],
      errors: [],
      renderUpMaterial: 0,
      renderUpCoverMaterial: 0
    }
  },
  computed: {
    actions () {
      return [
        {
          label: 'Salvar',
          action: this.newMaterial,
          icon: 'mdi-floppy',
          disabled: this.btnDisable
        }
      ]
    }
  },
  watch: {
    subject_id () {
      if (this.subject_id !== null) {
        this.name = this.subjects.find((item) => item.id === this.subject_id).name
        this.enableName = false
      } else {
        this.name = ''
        this.enableName = true
      }
    }
  },
  created () {
    this.subjectTextBooksCreate = utils.checkPermissions('subject-textbooks.create')
    this.fetchAll()
  },
  methods: {
    listTypes,
    listVolumeTypes,
    async fetchAll () {
      this.types = listTypes()
      // this.volumes = listVolumeTypes()
      await this.getGradeLevelGroups()
      await this.getTeachingSystems()
    },

    getErrors (data) {
      if (data.data.errors) {
        this.errors = utils.setErrorsData(data.data.errors)
      } else {
        throw (data)
      }
    },
    checkSavePermission () {
      if (this.temporary_upload_file && this.cover_temporary_upload_file) {
        this.btnDisable = false
      } else {
        this.btnDisable = true
      }
    },
    setMaterialFile (data) {
      this.temporary_upload_file = data.id
      this.checkSavePermission()
    },
    setCoverMaterialFile (data) {
      this.cover_temporary_upload_file = data.id
      this.checkSavePermission()
    },
    async getTeachingSystems () {
      try {
        const response = await this.$api.getTeachingSystems()
        this.systems = response
      } catch (e) {
        // console.log(e.response)
      }
    },
    async getGradeLevelGroups () {
      try {
        const response = await this.$api.getGradeLevelGroups()
        this.gradelevelGroups = response
      } catch (e) {
        // console.log(e.response)
      }
    },
    async getGradeLevel (id) {
      try {
        const response = await this.$api.getGradeLevels(id)
        this.gradelevels = response
        this.subjects = []
        this.enableName = false
      } catch (e) {
        // console.log(e.response)
      }
    },
    async getSubjects () {
      try {
        const response = await this.$api.getSubjectsOfGradeLevel(this.grade_level_id, this.teaching_system_id)
        this.subjects = [
          ...response,
          {
            id: null,
            name: 'Outra'
          }
        ]
        this.subject_id = this.subjects[0].id
        if (this.subject_id === null) {
          this.name = ''
          this.enableName = true
        }
        await this.getVolumes()
      } catch (e) {
        // console.log(e)
      }
    },
    async getVolumes () {
      try {
        this.volumes = await this.$api.getVolumes(this.grade_level_id, this.teaching_system_id)
      } catch (e) {
        // console.log(e)
      }
    },
    validate () {
      return this.$refs.form.validate()
    },
    sizes (data) {
      return utils.size(data)
    },
    uploadMaterial () {
      this.waitEnable = false
      this.waitingUploadCover = false
    },
    goToTextBookList () {
      this.$router.push({ name: 'conteudos.materiais' })
    },

    modalNewMaterial () {
      this.modalListOrNew = false
      this.name = ''
      // this.is_teacher_material = false
      // this.is_published = false
      // this.should_publish_after_processing = true
      // this.teaching_system_id = null
      this.temporary_upload_file = null
      this.cover_temporary_upload_file = null
      // this.publishing_year = null
      // this.grade_level_group = null
      // this.grade_level_id = null
      this.subject_id = null
      // this.type = null
      this.order = 0
      // this.volume = null
      this.renderUpMaterial += 1
      this.renderUpCoverMaterial += 1
      this.waitEnable = true
      this.btnDisable = true
      this.fetchAll()
    },
    async newMaterial () {
      this.loading = true
      this.waitEnable = true
      const data = {
        teaching_system_id: this.teaching_system_id,
        grade_level_id: this.grade_level_id,
        temporary_upload_file_id: this.temporary_upload_file,
        cover_temporary_upload_file_id: this.cover_temporary_upload_file,
        name: this.name,
        subject_id: this.subject_id,
        publishing_year: this.publishing_year,
        order: this.order,
        is_teacher_material: this.is_teacher_material,
        type: this.type,
        should_publish_after_processing: this.should_publish_after_processing,
        volume_id: this.volume
      }
      try {
        await this.$api.NewMaterialTextBook(data)
        this.snackbar = true
        this.msgSnackbar = 'Material cadastrado com sucesso'
        this.modalListOrNew = true
      } catch (e) {
        this.loading = false
        this.waitEnable = false
        // console.log(e.response)
        this.getErrors(e.response)
      }
      this.loading = false
      this.waitEnable = false
    }
  }
}
</script>
