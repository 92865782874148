<template>
  <ItemPage
    :title="gradeLevelGroup.name ? gradeLevelGroup.name : ''"
    size="md"
  >
    <v-simple-table
      v-if="gradeLevelsView"
      fixed-header
    >
      <template v-if="loading">
        <Load :loadingmsg="loadingMsg" />
      </template>
      <v-data-table
        v-else
        :headers="headers"
        :items="gradeLevelGroup.grade_levels"
        hide-default-footer
        item-key="id"
        @click:row="editGradeLevelSubject($event)"
      >
        <template #item.name="{ item }">
          <router-link :to="{ name: 'conteudos-v2.sistema-de-ensino.teachingSystemID.segmento.gradeLevelGroupID.ano.gradeLevelID.materia', params: { gradeLevelID: item.id } }">
            {{ item.name }}
          </router-link>
        </template>
      </v-data-table>
    </v-simple-table>

    <template v-else>
      usuário sem permissão
    </template>
  </ItemPage>
</template>

<script>
import Load from '@/components/Loading.vue'
import ItemPage from '@/components/pages/ItemPage.vue'
import utils from '@/api/utils.js'

export default {
  components: {
    Load,
    ItemPage
  },
  data () {
    return {
      gradeLevelsView: false,
      loading: false,
      loadingSave: false,
      gradeLevelGroup: {},
      headers: [
        {
          text: 'id',
          align: 'start',
          value: 'id'
        },
        {
          text: 'Nome',
          align: 'start',
          value: 'name'
        },
        {
          text: 'Abreviatura',
          align: 'start',
          value: 'short_name'
        }
      ]
    }
  },
  computed: {
    teachingSystemID () {
      return this.$route.params.teachingSystemID
    },
    gradeLevelGroupID () {
      return this.$route.params.gradeLevelGroupID
    },
    gradeLevelID () {
      return this.$route.params.gradeLevelID
    }
  },
  created () {
    this.fetchAll()
  },
  methods: {
    async fetchAll () {
      this.gradeLevelsView = utils.checkPermissions('grade-levels.view-any')
      await this.getGradeLevel()
    },
    async getGradeLevel () {
      this.loading = true
      this.loadingMsg = 'Carregando lista de cursos...'
      try {
        this.gradeLevelGroup = await this.$api.getGradeLevelGroup(this.gradeLevelGroupID)
      } catch {
        // console.log('Erro ao obter os cursos')
      }
      this.loading = false
    },
    editGradeLevelSubject (event) {
      this.$router.push({ name: 'conteudos-v2.sistema-de-ensino.teachingSystemID.segmento.gradeLevelGroupID.ano.gradeLevelID.materia', params: { gradeLevelID: event.id } })
    },
    validate () {
      return this.$refs.form.validate()
    }
  }
}
</script>
