<template>
  <v-dialog
    width="500"
    persistent
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-card>
      <v-card-title>
        {{ action }} {{ title }}
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
        >
          Tem certeza que deseja {{ action }} {{ title }}, {{ name }} ?
        </v-form>
      </v-card-text>
      <v-card-text>
        <div class="d-flex justify-space-between">
          <v-btn
            color="primary"
            text
            @click="close()"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="onAction()"
          >
            {{ action }}
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {
  },
  props: {
    title: { type: String, default: undefined },
    action: { type: String, default: undefined },
    name: { type: String, default: undefined }
  },
  data: () => ({
  }),
  created () {
  },
  methods: {
    close () {
      this.$emit('close')
    },

    onAction () {
      this.$emit('confirm')
      this.close()
    }
  }
}
</script>
