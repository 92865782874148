<template>
  <div>
    <template v-if="loading">
      <Load :loading-msg="loadingMsg" />
    </template>
    <template v-else>
      <v-card-title>
        Cadastro da Escola
      </v-card-title>
      <v-text-field
        v-model="dataschool.name"
        prepend-icon="mdi-account"
        :error-messages="formError.name"
        label="Nome"
      />
      <v-text-field
        v-model="dataschool.code"
        :error-messages="formError.code"
        prepend-icon="mdi-account"
        label="Código"
      />
      <div class="d-flex justify-space-between">
        <v-btn
          color="primary"
          text
          @click="close()"
        >
          fechar
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="save()"
        >
          Salvar
        </v-btn>
      </div>
    </template>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
    >
      {{ msgSnackbar }}

      <template #action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Load from '@/components/Loading.vue'

export default {
  name: 'Editschool',

  components: {
    Load
  },

  data: () => ({
    dataschool: {},
    formError: {},
    loading: false,
    loadingMsg: '',
    snackbar: false,
    msgSnackbar: '',
    erro: false,
    timeout: 3000
  }),
  created () {
    // this.verifica()
  },
  methods: {
    close () {
      const action = { action: 'exit', school: this.dataschool }
      this.$emit('getAction', action)
    },
    async save () {
      const data = this.dataschool
      this.updateschool = false
      try {
        this.loading = true
        this.loadingMsg = 'Salvando...'
        const response = await this.$api.saveSchool(data)
        this.$router.push({ name: 'escolas.id', params: { id: response.id } })
        this.getSchool()
      } catch (e) {
        this.loading = false
        this.formError = e.response.data.errors
        this.snackbar = true
        this.msgSnackbar = 'Erro ao tentar salvar'
      }
    },
    update () {
      const action = { action: 'update', school: this.dataschool }
      this.$emit('getAction', action)
    }
  }
}
</script>
