export const types = {
  Student: 0,
  Teacher: 1,
  Maintainer: 2,
  ParentOfStudent: 3,
  Subscriber: 4,
  Coordinator: 5
}

export const displayNames = {
  [types.Student]: 'Aluno',
  [types.Teacher]: 'Professor',
  [types.Maintainer]: 'Mantenedor',
  [types.ParentOfStudent]: 'Responsável',
  [types.Subscriber]: 'Assinante',
  [types.Coordinator]: 'Coordenador'
}

export function getTextForDisplay (type) {
  const name = displayNames[type]

  if (name === undefined) {
    return ''
  }

  return name
}

export default {
  types,
  displayNames,
  getTextForDisplay
}
