<template>
  <v-dialog
    width="500"
    persistent
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-card class="px-4 py-4">
      <v-card-title>
        Nova questão
      </v-card-title>
      <v-progress-linear
        v-if="loading"
        color="amber"
        indeterminate
      />
      <v-form
        ref="form"
        class="mb-5"
      >
        <v-select
          v-model="subject"
          :items="subjects"
          :disabled="loading"
          :rules="rulesSubject"
          item-text="name"
          item-value="id"
          label="Matéria"
          prepend-icon="mdi-note-text"
          @change="getTopics"
        />
        <v-select
          v-model="topic"
          :items="topics"
          :disabled="subject === null ? true : loading"
          :rules="rulesTopic"
          item-text="name"
          item-value="id"
          label="Tópico"
          prepend-icon="mdi-note-text"
        />
        <div class="ml-8 text--secondary body-2">
          Se o tópico não existir no material, selecione "Sem tópico".
        </div>
      </v-form>
      <div class="d-flex justify-space-between">
        <v-btn
          color="primary"
          :disabled="loading"
          text
          @click="close()"
        >
          fechar
        </v-btn>
        <v-btn
          color="primary"
          :disabled="topic === null ? true : loading"
          text
          @click="save()"
        >
          Salvar
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import handlesServerValidation from '@/mixins/handles-server-validation.js'
import { getRealId } from '@/enums/gradeLevelAudeExam'

export default {
  components: {
  },

  mixins: [
    handlesServerValidation
  ],
  props: {
    idGroup: { type: Number, default: null },
    gradeLevel: { type: Number, default: null },
    status: { type: Number, default: null }
  },

  data: () => ({
    rulesLanguage: [
      value => {
        if (value != null) return true
        return 'Definir a língua estrangeira é obrigatório.'
      }
    ],
    rulesTopic: [
      value => value !== null || 'Tópico é obrigatório.'
    ],
    rulesSubject: [
      value => !!value || 'Matéria é obrigatório.'
    ],
    teachingSystem: 1,
    loading: false,
    realGradeLevel: null,
    subject: null,
    subjects: [],
    topic: null,
    topics: []
  }),

  computed: {
    foreignLanguage () {
      const subjectName = this.subjects.find(s => s.id === this.subject)?.name

      switch (subjectName) {
        case 'Língua Inglesa':
          return 1

        case 'Língua Espanhola':
          return 2
      }

      return 0
    }
  },

  async created () {
    this.realGradeLevel = getRealId(this.gradeLevel)
    await this.getSubjects()
  },

  methods: {
    getRealId,

    validate () {
      return this.$refs.form.validate()
    },

    close () {
      this.$refs.form.reset()
      this.$emit('close')
    },

    async getSubjects () {
      try {
        const responseSubjects = await this.$api.getSubjectsOfGradeLevel(this.realGradeLevel, this.teachingSystem)
        this.subjects = responseSubjects
      } catch {
        // console.log('Erro ao listar Segmentos')
      }
    },

    async getTopics () {
      this.loading = true
      const data = await this.$api.getTopics(this.realGradeLevel, this.subject, this.teachingSystem)
      this.topics = data
      this.topics.push({
        name: 'Sem tópico',
        id: false
      })
      this.loading = false
    },

    async save () {
      const validate = this.validate()
      if (validate === false) {
        return false
      }

      const data = {
        topic_id: this.topic === false ? null : this.topic,
        foreign_language: this.foreignLanguage
      }

      try {
        const response = await this.$api.newAudeExamQuestions(this.idGroup, data)
        this.$store.dispatch('setSnackBar', { text: 'Questão criada com sucesso.' })
        this.close()
        this.$router.push({ name: 'conteudos.avaliacao-aude.grupo-de-questoes.id.status.gradelevel.questao', params: { idgroup: this.idGroup, status: this.status, gradelevel: this.gradeLevel, id: response.data.data.id } })
      } catch (e) {
        this.$store.dispatch('setSnackBar', { text: 'Ocorreu um erro ao tentar criar a questão.', color: 'red' })
      }
    }
  }
}
</script>
