<template>
  <div>
    <v-card-title>
      Incluir Tópico
    </v-card-title>
    <v-card-text>
      <v-form
        ref="form"
      >
        <v-select
          v-model="teachingSystem"
          :items="teachingSystems"
          :rules="rules"
          item-text="name"
          item-value="id"
          label="Sistema de Ensino"
        />
        <v-select
          v-model="gradeLevelGroup"
          :items="gradeLevelGroups"
          :rules="rules"
          item-text="name"
          item-value="id"
          label="Segmento"
          :disabled="teachingSystem ? false : true"
          @change="getGradeLevels"
        />
        <v-select
          v-model="gradeLevel"
          :items="gradeLevels ? gradeLevels : []"
          :rules="rules"
          item-text="name"
          item-value="id"
          label="Curso/Ano"
          :disabled="gradeLevelGroup ? false : true"
          @change="getSubjects"
        />
        <v-select
          v-model="subject"
          :items="subjects ? subjects : []"
          :rules="rules"
          item-text="name"
          item-value="id"
          label="Matéria"
          :disabled="gradeLevel ? false : true"
          @change="getTopics"
        />
        <v-select
          v-model="topic"
          :items="topics ? topics : []"
          :rules="rules"
          item-text="name"
          item-value="id"
          :disabled="subject ? false : true"
          label="Tópico"
          @change="getTopicName"
        />
        <v-select
          v-model="level"
          :items="levels"
          :rules="rules"
          item-text="name"
          item-value="id"
          :disabled="topic ? false : true"
          label="Dificuldade"
        />
      </v-form>
    </v-card-text>
    <v-card-text>
      <div class="d-flex justify-space-between">
        <v-btn
          color="primary"
          text
          @click="close()"
        >
          Fechar
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="save()"
        >
          Incluir
        </v-btn>
      </div>
    </v-card-text>
  </div>
</template>

<script>
export default {
  components: {
  },
  data: () => ({
    rules: [
      value => !!value || 'Preenchimento obrigatório.'
    ],
    teachingSystems: [],
    gradeLevelGroups: [],
    gradeLevels: [],
    subjects: [],
    topics: [],
    levels: [],
    teachingSystem: null,
    gradeLevelGroup: undefined,
    gradeLevel: undefined,
    subject: undefined,
    topic: undefined,
    level: undefined,
    gradeLevelGroupName: '',
    gradeLevelName: '',
    subjectName: '',
    topicName: ''
  }),

  computed: {
    selectedTeachingSystem () {
      if (!this.teachingSystem) {
        return false
      }

      return this.teachingSystems.find(t => t.id === this.teachingSystem)
    }
  },

  created () {
    this.fetchAll()
  },
  methods: {
    fetchAll () {
      this.fetchTeachingSystems()
      this.getGradeLevelGroups()
      this.getLevels()
    },
    validate () {
      return this.$refs.form.validate()
    },
    close () {
      this.$emit('close')
    },

    async getLevels () {
      const data = [
        {
          name: 'Muito fácil',
          id: 1
        },
        {
          name: 'Fácil',
          id: 2
        },
        {
          name: 'Moderado',
          id: 3
        },
        {
          name: 'Difícil',
          id: 4
        },
        {
          name: 'Muito difícil',
          id: 5
        }
      ]
      this.levels = data
    },

    async getGradeLevelGroups () {
      try {
        const responseGradeLevelGroups = await this.$api.getGradeLevelGroups()
        this.gradeLevelGroups = responseGradeLevelGroups
      } catch {
        // console.log('Erro ao listar Segmentos')
      }
    },

    getGradeLevelGroupName () {
      const name = this.gradeLevelGroups.find(gradeLevelGroup => gradeLevelGroup.id === this.gradeLevelGroup).name
      this.gradeLevelGroupName = name
    },

    async getGradeLevels () {
      this.gradeLevel = undefined
      this.subject = undefined
      this.topic = undefined
      try {
        const responseGradeLevels = await this.$api.getGradeLevelGroup(this.gradeLevelGroup)
        this.gradeLevels = responseGradeLevels.grade_levels
      } catch {
        // console.log('Erro ao listar Segmentos')
      }
      this.getGradeLevelGroupName()
    },

    async getVolumes () {
      try {
        this.volumes = await this.$api.getVolumes(this.$route.params.id, this.teachingSystem)
      } catch (e) {
        // console.log(e)
      }
    },

    async fetchTeachingSystems () {
      this.loading = true
      const data = await this.$api.getTeachingSystems()
      this.teachingSystems = data
      this.loading = false
    },

    setGradeLevel () {
      this.gradeLevel = this.gradeLevels.find(level => level.id === this.gradeLevel).id
    },

    async getSubjects () {
      this.topic = undefined
      this.subject = undefined
      this.level = null
      try {
        const responseSubjects = await this.$api.getSubjectsOfGradeLevel(this.gradeLevel, this.teachingSystem)
        this.subjects = responseSubjects
      } catch {
        // console.log('Erro ao listar Segmentos')
      }
      this.setGradeLevel()
    },

    getSubjectName () {
      const name = this.subjects.find(subject => subject.id === this.subject).name
      this.subjectName = name
    },

    async getTopics () {
      this.loading = true
      const data = await this.$api.getTopics(this.gradeLevel, this.subject, this.teachingSystem)
      this.topics = data
      this.getSubjectName()
    },

    getTopicName () {
      const name = this.topics.find(topic => topic.id === this.topic).name
      this.topicName = name
    },

    async save () {
      const validate = await this.validate()
      if (validate === false) {
        return false
      }

      // We shoud probably extract those functions or just use lodash.cloneDeep().
      const findById = (arr, id) => arr.find(item => item.id === id)
      const jsonClone = (obj) => JSON.parse(JSON.stringify(obj))
      const findClone = (arr, id) => jsonClone(findById(arr, id))

      // Normalize some values in a hacky way for now. Sorry.
      // eslint-disable-next-line camelcase
      const { grade_levels, ...gradeLevelGroup } = JSON.parse(JSON.stringify(this.gradeLevelGroups.find(glg => glg.id === this.gradeLevelGroup)))

      const gradeLevel = {
        grade_level_group: findClone(this.gradeLevelGroups, this.gradeLevelGroup),
        ...findClone(this.gradeLevels, this.gradeLevel)
      }

      const topic = {
        grade_level: gradeLevel,
        subject: findClone(this.subjects, this.subject),
        ...findClone(this.topics, this.topic)
      }

      const data = {
        topic_id: this.topic,
        grade_level_id: this.gradeLevel.id,
        difficulty: this.level,
        topicname: [this.selectedTeachingSystem.short_name, this.gradeLevelGroupName, this.gradeLevel.name, this.subjectName, this.topicName].join(' - '),
        // topic: findClone(this.topics, this.topic),
        topic,
        gradeLevel: findClone(this.gradeLevels, this.gradeLevel),
        subject: findClone(this.subjects, this.subject),
        gradeLevelGroup
      }
      this.$emit('save', data)
    }
  }
}
</script>
