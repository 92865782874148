<template>
  <div
    v-if="questionsView"
    fluid
  >
    <div class="white d-flex py-5 px-5 align-center">
      <h2 class="d-inline">
        Questões
      </h2>
      <v-spacer />
    </div>

    <div class="white px-4 py-1">
      <template v-if="loading">
        <Load :loading-msg="'Carregando'" />
      </template>
      <div v-else>
        <div
          v-for="question in questions"
          :key="question.id"
        >
          <div class="my-8">
            <div class="grey lighten-5 px-5 py-3 rounded">
              <router-link :to="{ name: 'conteudos.questoes.id', params: { id: question.id } }">
                Questão #{{ question.id }}
              </router-link>
              <span
                v-if="question.intranet_user"
                class="ml-3"
              >
                Criada por {{ question.intranet_user.name }} {{ dayjs(question.created_at).fromNow() }}
              </span>
            </div>
            <div class="px-5">
              <editor-content
                v-if="question.editor"
                :editor="question.editor"
                class="editor-content mt-5"
              />
              <div
                v-else
                class="red--text"
              >
                Conteúdo corrompido! Apague esta questão e cadastre-a novamente.
              </div>
            </div>
          </div>
          <v-divider />
        </div>
      </div>
    </div>

    <v-pagination
      v-model="page"
      :length="totalPages"
      :total-visible="5"
      class="mt-4"
    />
  </div>
</template>

<script>
import Load from '@/components/Loading.vue'
import utils from '@/api/utils.js'
import { Editor, EditorContent } from 'tiptap'
import {
  HardBreak,
  Bold,
  Italic,
  History
} from 'tiptap-extensions'
import Image from '@/components/contents/questions/editor/nodes/Image'
import Katex from '@/components/contents/questions/editor/nodes/Katex'
import dayjs from '@/dayjs'

export default {
  components: {
    Load, EditorContent
  },
  props: {
    topic: { type: Number, default: undefined }
  },

  data: () => ({
    loading: true,
    questionsView: false,
    questionsCreate: false,
    disabledBtn: true,

    // Questions
    questions: [],

    // Pagination
    page: 1,
    totalPages: 1,

    // Filters
    teachingSystems: [],
    teachingSystem: null,
    gradeLevelGroupsAndLevels: [],
    gradeLevel: null,
    subjects: [],
    subject: null,
    topics: []
  }),

  watch: {
    page (value) {
      this.fetchPage(value)
    }
  },

  async created () {
    this.fetchPage(1)
  },

  methods: {
    async fetchPage (page) {
      this.questionsView = utils.checkAnyOrOwn('questions.view-any', 'questions.view-own')
      this.questionsCreate = utils.checkPermissions('questions.create')
      this.loading = true

      const query = [`page=${page}`, 'with=intranetUser']

      if (this.topic) {
        query.push(`topic_id=${this.topic}`)
      }

      const data = await this.$api.listQuestions(query.join('&'))

      for (const question of data.data) {
        try {
          question.editor = new Editor({
            editable: false,
            extensions: [
              new HardBreak(),
              new Bold(),
              new Image(),
              new Italic(),
              new History(),
              new Katex()
            ],
            content: JSON.parse(question.text_data).statement
          })
        } catch (e) {
          question.editor = null
        }
      }

      if (this.questions.length > 0) {
        for (const question of this.questions) {
          question.editor && question.editor.destroy()
        }
      }

      this.questions = data.data
      this.totalPages = data.meta.last_page

      this.loading = false
    },

    async filter () {
      this.busy = true
      this.fetchPage(this.page)
      this.busy = false
    },

    dayjs
  }
}
</script>
