export const types = {
  queue: 1,
  processing: 2,
  processed: 3,
  failed: 4
}
// export function listTypes () {
//   const data = [
//     { name: 'Teórico', id: 1 },
//     { name: 'Atividade', id: 2 }
//   ]
//   return data
// }
export const displayNames = {
  [types.queue]: 'Fila',
  [types.processing]: 'Em processamento',
  [types.processed]: 'Processado',
  [types.failed]: 'Falha'
}

export function getTextForDisplay (type) {
  const name = displayNames[type]

  if (name === undefined) {
    return ''
  }

  return name
}

export default {
  types,
  displayNames,
  getTextForDisplay
}
