<template>
  <ItemPage
    :title="subject.name ? subject.name : ''"
    size="md"
  >
    <template
      v-if="topicsView"
    >
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Pesquise pelo nome do tópico"
        single-line
        hide-details
      />
      <v-simple-table fixed-header>
        <template v-if="loading">
          <Load :loadingmsg="loadingMsg" />
        </template>
        <v-data-table
          v-else
          :headers="headers"
          :items="topics"
          :items-per-page="10"
          :search="search"
          item-key="id"
          @click:row="editTopic($event)"
        >
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template #item.name="{ item }">
            <router-link :to="{ name: '', params: { id: item.id } }">
              {{ item.name }}
            </router-link>
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.topicName="{ item }">
            {{ item.topicName }}
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.volume_id="{ item }">
            {{ findVolume(item.volume_id).name }}
          </template>
        </v-data-table>
      </v-simple-table>
    </template>

    <template v-else>
      usuário sem permissão
    </template>
  </ItemPage>
</template>

<script>
import Load from '@/components/Loading.vue'
import utils from '@/api/utils.js'
import ItemPage from '@/components/pages/ItemPage.vue'
import { getTextForDisplay } from '@/enums/volumeTypes'

export default {
  components: {
    Load,
    ItemPage
  },
  data () {
    return {
      topicsView: false,
      loading: false,
      loadingSave: false,
      topics: [],
      subject: {},
      volumes: [],
      search: '',
      headers: [
        {
          text: 'id',
          align: 'start',
          value: 'id'
        }, {
          text: 'Nome do tópico',
          align: 'start',
          value: 'name'
        }, {
          text: 'Volume',
          align: 'start',
          value: 'volume_id'
        }
      ]
    }
  },
  computed: {
    teachingSystemID () {
      return this.$route.params.teachingSystemID
    },
    gradeLevelGroupID () {
      return this.$route.params.gradeLevelGroupID
    },
    gradeLevelID () {
      return this.$route.params.gradeLevelID
    },
    subjectID () {
      return this.$route.params.subjectID
    },
    topicID () {
      return this.$route.params.topicID
    }
  },
  created () {
    this.fetchAll()
  },
  methods: {
    getTextForDisplay,
    async fetchAll () {
      this.topicsView = utils.checkPermissions('topics.view-any')
      await this.getTopicsOfSubject()
      await this.getSubject()
    },
    async getSubject () {
      this.loading = true
      this.loadingMsg = 'Carregando lista de cursos...'
      try {
        this.subject = await this.$api.getSubject(this.subjectID)
      } catch {
        // console.log('Erro ao obter a matéria')
      }
      this.loading = false
    },
    async getTopicsOfSubject () {
      this.loading = true
      this.loadingMsg = 'Carregando lista de tópicos...'
      try {
        [
          this.topics,
          this.volumes
        ] = await Promise.all([
          this.$api.getTopicsOfSubject(this.subjectID, this.gradeLevelID, this.teachingSystemID),
          this.$api.getVolumes(this.gradeLevelID, this.teachingSystemID)
        ])
      } catch {
        // console.log('Erro ao listar os tópicos')
      }
      this.loading = false
    },
    findVolume (id) {
      return this.volumes.find(v => v.id === id)
    },
    editTopic (event) {
      this.$router.push({ name: 'conteudos-v2.sistema-de-ensino.teachingSystemID.segmento.gradeLevelGroupID.ano.gradeLevelID.materia.subjectID.volume.volumeID.topico.topicID', params: { volumeID: event.volume_id, topicID: event.id } })
    },
    sizes (data) {
      return utils.size(data)
    },
    validate () {
      return this.$refs.form.validate()
    }
  }
}
</script>
