export const types = {
  Teoric: 1,
  Activity: 2,
  Additional: 3
}
export function listTypes () {
  const data = [
    { name: 'Teórico', id: 1 },
    { name: 'Atividade', id: 2 },
    { name: 'Complementar', id: 3 }
  ]
  return data
}
export const displayNames = {
  [types.Teoric]: 'Teórico',
  [types.Activity]: 'Atividade',
  [types.Additional]: 'Complementar'
}

export function getTextForDisplay (type) {
  const name = displayNames[type]

  if (name === undefined) {
    return ''
  }

  return name
}

export default {
  types,
  listTypes,
  displayNames,
  getTextForDisplay
}
