<template>
  <v-dialog
    width="500"
    persistent
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-card class="px-4 py-4">
      <v-card-title>
        Nova área do conhecimento
      </v-card-title>
      <v-progress-linear
        v-if="loading"
        color="amber"
        indeterminate
      />
      <v-form
        ref="form"
      >
        <!-- <v-text-field
          v-model="title"
          prepend-icon="mdi-note-text"
          :error-messages="formErrors.title"
          :rules="rulesTitle"
          :disabled="loading"
          label="Área do conhecimento"
          @input="formErrors.title = ''"
        /> -->
        <v-select
          v-model="title"
          class="my-4"
          prepend-icon="mdi-school"
          :error-messages="formErrors.title"
          :disabled="loading"
          :items="itemsTitle"
          label="Área do conhecimento"
          dense
          return-object
          @input="onTitleInput"
        />
        <v-text-field
          v-if="isCustomTitle"
          v-model="customTitle"
          class="my-4"
          prepend-icon="mdi-note-text"
          :error-messages="formErrors.customTitle"
          :rules="rulesCustomTitle"
          :disabled="loading"
          label="Área do conhecimento personalizada"
          @input="formErrors.customTitle = ''"
        />

        <v-text-field
          v-if="!isCustomTitle"
          :value="caption"
          class="my-4"
          prepend-icon="mdi-note-text"
          readonly
          :disabled="loading"
          label="Disciplinas"
        />
        <v-text-field
          v-else
          v-model="customCaption"
          class="my-4"
          prepend-icon="mdi-note-text"
          :error-messages="formErrors.customCaption"
          :rules="rulesCustomCaption"
          :disabled="loading"
          label="Disciplinas personalizadas"
          @input="formErrors.caption = ''"
        />
      </v-form>
      <div class="d-flex justify-space-between">
        <v-btn
          color="primary"
          text
          @click="close()"
        >
          fechar
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="save()"
        >
          Salvar
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import handlesServerValidation from '@/mixins/handles-server-validation.js'

export default {
  components: {
  },

  mixins: [
    handlesServerValidation
  ],
  props: {
    idExam: { type: Number, default: null }
  },

  data: () => ({
    rulesCustomTitle: [
      value => !!value || 'É obrigatório inserir área do conhecimento.',
      value => (value && value.length >= 3) || 'Min 3 caracteres',
      value => (value && value.length <= 250) || 'Max 250 caracteres'
    ],
    rulesCustomCaption: [
      value => !!value || 'É obrigatório inserir disciplina',
      value => (value && value.length >= 3) || 'Min 3 caracteres',
      value => (value && value.length <= 250) || 'Max 250 caracteres'
    ],
    title: null,
    customTitle: null,
    customCaption: null,
    loading: false,
    itemsTitle: [
      {
        value: 0,
        text: 'Ciências Humanas e suas Tecnologias',
        caption: 'História, Geografia, Filosofia e Sociologia.'
      },
      {
        value: 1,
        text: 'Ciências da Natureza e suas Tecnologias',
        caption: 'Química, Física e Biologia.'
      },
      {
        value: 2,
        text: 'Linguagens, Códigos e suas Tecnologias',
        caption: 'Língua Portuguesa, Literatura, Língua Inglesa, Língua Espanhola e Arte.'
      },
      {
        value: 3,
        text: 'Matemática e suas Tecnologias',
        caption: 'Matemática.'
      },
      {
        value: -1,
        text: 'Outra (especifique)',
        caption: null
      }
    ]
  }),

  computed: {
    isCustomTitle () {
      return this.title && this.title.value === -1
    },

    caption () {
      return this.title?.caption
    }
  },

  methods: {
    onTitleInput () {
      this.formErrors.title = ''
    },

    validate () {
      return this.$refs.form.validate()
    },

    close () {
      this.$refs.form.reset()
      this.$emit('close')
    },

    async save () {
      const validate = this.validate()
      if (validate === false) {
        return false
      }
      this.loading = true

      try {
        const data = {}

        if (this.isCustomTitle) {
          data.title = this.customTitle
          data.caption = this.customCaption
        } else {
          data.title = this.title.text
          data.caption = this.caption
        }

        await this.$api.newQuestionsGroup(this.idExam, data)
        this.$store.dispatch('setSnackBar', { text: 'Grupo de questões criado com sucesso.' })
        this.$emit('reload')
        this.close()
      } catch (e) {
        this.loading = false
        this.$store.dispatch('setSnackBar', { text: 'Ocorreu um erro ao tentar criar o grupo de questões.', color: 'red' })
        this.getErrors(e)
      }
      this.loading = false
    }
  }
}
</script>
