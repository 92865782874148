import Vue from 'vue'
import VueRouter from 'vue-router'
import api from '@/plugins/api.js'
import generated from './generated'

Vue.use(VueRouter)

const routes = [
  ...generated
]

const router = new VueRouter({
  mode: 'history',
  routes
})
router.beforeEach((to, from, next) => {
  if (to.name !== 'index' && api.checkLogin() === false) next({ name: 'index' })
  else next()
})

export default router
