<template>
  <ItemPage
    title="Criar Questão"
    size="sm"
    :actions="actions"
  >
    <div class="white">
      <v-form ref="form">
        <div class="d-flex">
          <div class="text-subtitle-1">
            Tópicos
          </div>

          <v-spacer />

          <v-btn
            color="primary"
            text
            :disabled="busy"
            @click="openTopicDialog"
          >
            <v-icon left>
              mdi-plus
            </v-icon>
            Adicionar Tópico
          </v-btn>
        </div>

        <v-simple-table class="mt-5">
          <thead>
            <tr>
              <th>Tópico</th>
              <th>Dificuldade</th>
              <th class="text-right">
                Ação
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="(topic, index) in topics"
              :key="topic.topic_id"
            >
              <td> {{ topic.topicname }}</td>

              <td>{{ getDifficultyName(topic.difficulty) }}</td>

              <td class="text-right">
                <v-btn
                  :disabled="busy"
                  text
                  @click="removeTopic(index)"
                >
                  <v-icon left>
                    mdi-delete
                  </v-icon>
                  Remover
                </v-btn>
              </td>
            </tr>
          </tbody>
        </v-simple-table>

        <div
          class="mt-3 caption error--text"
          style="min-height: 20px;"
        >
          {{ errors.topics ? errors.topics[0] : '' }}
        </div>

        <div class="d-flex mt-11">
          <div class="text-subtitle-1">
            Tags
          </div>

          <v-spacer />

          <v-btn
            color="primary"
            text
            :disabled="busy"
            @click="openTagDialog"
          >
            <v-icon left>
              mdi-plus
            </v-icon>
            Adicionar Tag
          </v-btn>
        </div>

        <v-simple-table class="mt-5">
          <thead>
            <tr>
              <th>Tag</th>
              <th class="text-right">
                Ação
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="(tag, index) in tags"
              :key="tag.tag"
            >
              <td> {{ tag.tagname }}</td>

              <td class="text-right">
                <v-btn
                  :disabled="busy"
                  text
                  @click="removeTag(index)"
                >
                  <v-icon left>
                    mdi-delete
                  </v-icon>
                  Remover
                </v-btn>
              </td>
            </tr>
          </tbody>
        </v-simple-table>

        <div
          class="mt-3 caption error--text"
          style="min-height: 20px;"
        >
          {{ errors.tags ? errors.tags[0] : '' }}
        </div>

        <v-row class="mt-8 align-center">
          <v-col>
            <v-select
              v-model="questionBoard"
              :items="questionBoards"
              :error-messages="errors.question_board_id"
              item-text="name"
              item-value="id"
              label="Banca"
              :disabled="busy"
              @change="newQuestionBoard"
            />
          </v-col>
          <v-col
            v-if="questionBoard"
            cols="auto"
          >
            <v-btn
              text
              :disabled="busy"
              @click="questionBoard = null"
            >
              <v-icon>mdi-close</v-icon>
              Remover Banca
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>

    <v-dialog
      v-model="dialogTopic"
      width="500"
      persistent
    >
      <v-card>
        <template v-if="request">
          <Load :loadingmsg="loadingMsg" />
        </template>
        <template v-else>
          <AddTopic
            @close="dialogTopic = false"
            @save="addTopic($event)"
          />
        </template>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogTag"
      width="500"
      persistent
    >
      <v-card>
        <template v-if="request">
          <Load :loadingmsg="loadingMsg" />
        </template>
        <template v-else>
          <AddTag
            @close="dialogTag = false"
            @save="addTag($event)"
          />
        </template>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
    >
      {{ msgSnackbar }}

      <template #action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </ItemPage>
</template>

<script>
import Load from '@/components/Loading.vue'
import AddTopic from '@/components/contents/questions/Add_Topic.vue'
import AddTag from '@/components/contents/questions/Add_Tag.vue'
import utils from '@/api/utils.js'
import ItemPage from '@/components/pages/ItemPage.vue'
import { getTextForDisplay as getDifficultyName } from '@/enums/questionDifficultyType'

export default {
  components: {
    Load,
    AddTopic,
    AddTag,
    ItemPage
  },

  data () {
    return {
      rules: [
        value => utils.size(value) > 0 || 'Preenchimento obrigatório, selecione ou use o botão para adicionar.'
      ],
      questionsView: false,
      questionsCreate: false,
      dialogTag: false,
      dialogTopic: false,
      errors: {},
      selectedTags: [],
      tags: [],
      topics: [],
      questionBoards: [],
      questionBoard: null,
      request: false,
      loading: false,
      loadingMsg: '',
      contents: [],
      snackbar: false,
      msgSnackbar: '',
      timeout: 3000,
      busy: false
    }
  },

  computed: {
    actions () {
      return [
        {
          label: 'Salvar',
          action: this.submit,
          icon: 'mdi-floppy',
          disabled: this.busy
        }
      ]
    }
  },
  async created () {
    this.fetchAll()
  },

  methods: {
    async fetchAll () {
      const data = await this.$api.getQuestionBoards()
      this.questionBoards = data
      this.questionsView = utils.checkPermissions('questions.view-any')
      this.questionsCreate = utils.checkPermissions('questions.create')
    },
    validate () {
      return this.$refs.form.validate()
    },

    // validate () {
    //   if (utils.size(this.selectedTopics) === 0) {
    //     this.erro.topics = 'Obrigatório'
    //     return false
    //   }
    //   if (utils.size(this.selectedTags) === 0) {
    //     this.erro.tags = 'Obrigatório'
    //     return false
    //   }
    //   return true
    // },

    openTopicDialog () {
      this.dialogTopic = true
    },

    openTagDialog () {
      this.dialogTag = true
    },

    addTopic (data) {
      if (this.errors.topics) {
        this.errors.topics = undefined
      }

      if (this.topics.some((t) => {
        return (t.topic_id === data.topic_id) &&
          t.grade_level_id === data.grade_level_id
      })) {
        alert('Esta combinação de Ano e Tópico já foi adicionada.')
        return
      }

      this.topics.push(data)
    },

    removeTopic (index) {
      this.topics.splice(index, 1)
    },

    addTag (data) {
      if (this.tags.some(t => t.tag === data.tag)) {
        alert('Essa tag já foi adicionada')
        return
      }

      this.tags.push(data)
    },

    removeTag (index) {
      this.tags.splice(index, 1)
    },

    newQuestionBoard (data) {
      this.questionBoard = data
    },

    async submit () {
      this.errors = []
      this.busy = true

      const validate = await this.validate()
      if (validate === false) {
        return false
      }

      const data = {
        question_board_id: this.questionBoard,
        tags: this.tags.map(t => t.tag),
        topics: this.topics
      }

      try {
        const question = await this.$api.newQuestion(data)

        this.$router.push({ name: 'conteudos.questoes.editar.id', params: { id: question.id } })
      } catch (e) {
        if (e.response && e.response.status === 422) {
          this.errors = e.response.data.errors
        }

        this.busy = false
      }
    },

    getDifficultyName
  }
}
</script>
