<template>
  <v-dialog
    width="500"
    persistent
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-card :loading="loading">
      <v-card-title>
        Cadastro de Conteúdo
      </v-card-title>

      <v-card-text>
        <v-form
          ref="form"
          :disabled="loading"
        >
          <v-text-field
            v-model="dataContent.name"
            :error-messages="formError.name"
            :rules="rulesName"
            label="Nome"
            @input="formError.name = ''"
          />

          <v-text-field
            v-model="topicName"
            :rules="rulesTopic"
            label="Tópico"
            readonly
            @click="dialogTopic = true"
          />

          <v-select
            v-model="dataContent.type"
            :error-messages="formError.type"
            :items="contentType"
            item-text="typeName"
            item-value="value"
            label="Tipo"
            :rules="rulesType"
            @input="formError.type = ''"
          />

          <v-text-field
            v-model="dataContent.link"
            :error-messages="formError.data"
            :rules="rulesData"
            label="Link do youtube"
            @input="formError.data = ''"
          />

          <v-text-field
            v-model="dataContent.duration"
            type="number"
            :error-messages="formError.duration"
            :rules="rulesDuration"
            label="Duração em segundos"
            @input="formError.duration = ''"
          />

          <v-textarea
            v-model="dataContent.description"
            :error-messages="formError.description"
            counter
            label="Descrição"
            @input="formError.description = ''"
          />
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="primary"
          text
          @click="$emit('close')"
        >
          Fechar
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>

      <v-dialog
        v-model="dialogTopic"
        width="500"
        persistent
      >
        <v-card>
          <AddTopic
            @close="dialogTopic = false"
            @save="addTopic($event)"
          />
        </v-card>
      </v-dialog>
    </v-card>
  </v-dialog>
</template>

<script>
import AddTopic from '@/components/contents/questions/Add_Topic.vue'
import { displayNames as difficultyDisplayNames } from '@/enums/questionDifficultyType'
import utils from '@/api/utils.js'

export default {
  components: {
    AddTopic
  },

  data: () => ({
    rulesName: [
      value => !!value || 'Nome é obrigatório.',
      value => (value && value.length >= 3) || 'Min 3 caracteres'
    ],
    rulesTopic: [
      value => !!value || 'Tópico é obrigatório.'
    ],
    rulesType: [
      v => !!v || 'Tipo é obrigatório'
    ],
    rulesData: [
      v => !!v || 'Link é obrigatório'
    ],
    rulesDuration: [
      v => !!v || 'Duração é obrigatório'
    ],
    dataContent: {
      name: undefined,
      type: 0,
      description: undefined
    },
    contentType: [],
    dialogTopic: false,
    formError: {},
    level: '',
    levels: [],
    saveSuccess: false,
    loading: false,
    topicId: null,
    difficulty: null,
    topicName: ''
  }),

  created () {
    this.fetchAll()
  },

  methods: {
    fetchAll () {
      this.contentType = utils.contentType()
      this.getLevels()
    },

    validate () {
      return this.$refs.form.validate()
    },

    getLevels () {
      this.levels = utils.objectMap(difficultyDisplayNames, (v, k) => ({ name: v, id: k }))
    },

    addTopic (data) {
      this.topicId = data.topic_id
      this.difficulty = data.difficulty
      this.topicName = data.topicname
      this.dialogTopic = false
    },

    async save () {
      const validated = await this.validate()
      if (!validated) {
        return false
      }

      this.loading = true

      const data = {
        name: this.dataContent.name,
        type: this.dataContent.type,
        topics: [this.topicId],
        duration: this.dataContent.duration,
        difficulty: this.difficulty,
        link: this.dataContent.link,
        description: this.dataContent.description
      }

      try {
        const response = await this.$api.quickSaveMediaContent(data)
        this.$router.push({ name: 'conteudos.midias.id', params: { id: response.id } })
      } catch (e) {
        // console.log(e)
      }

      this.loading = false
    }
  }
}
</script>
