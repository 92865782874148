<template>
  <ItemPage
    title="Matérias"
    size="md"
    :actions="actions"
  >
    <template
      v-if="subjectsView"
    >
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Pesquise pelo nome ou abreviatura"
        single-line
        hide-details
      />
      <v-simple-table fixed-header>
        <template v-if="loading">
          <Load :loadingmsg="loadingMsg" />
        </template>
        <div
          v-else
          class="mr-5 ml-5"
        >
          <v-data-table
            :headers="headers"
            :items="contents"
            :items-per-page="15"
            :search="search"
            item-key="id"
            @click:row="editSubject($event)"
          >
            <!-- <template v-slot:item.enabled="{ item }">
              {{ item.enabled }}
            </template> -->
          </v-data-table>
        </div>
      </v-simple-table>
    </template>
    <template v-else>
      usuário sem permissão
    </template>
    <v-dialog
      v-model="dialogAddSubject"
      width="500"
      persistent
    >
      <v-card>
        <template v-if="request">
          <Load :loadingmsg="loadingMsg" />
        </template>
        <template v-else>
          <AddSettings
            :key="renderComponent"
            title="Matéria"
            @close="dialogAddSubject = false"
            @save="newSubject($event)"
          />
        </template>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
    >
      {{ msgSnackbar }}

      <template #action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </ItemPage>
</template>

<script>
import Load from '@/components/Loading.vue'
import AddSettings from '@/components/settings/Add_Settings.vue'
import utils from '@/api/utils.js'
import ItemPage from '@/components/pages/ItemPage.vue'

export default {
  components: {
    Load,
    ItemPage,
    AddSettings
  },
  data () {
    return {
      subjectsView: false,
      subjectsCreate: false,
      loading: false,
      loadingMsg: '',
      request: false,
      dialogAddSubject: false,
      renderComponent: 0,
      contents: [],
      snackbar: false,
      msgSnackbar: '',
      timeout: 3000,
      search: '',
      headers: [
        {
          text: 'ID',
          align: 'start',
          value: 'id'
        },
        {
          text: 'Nome',
          align: 'start',
          value: 'name'
        },
        {
          text: 'Abreviatura',
          align: 'start',
          value: 'short_name'
        },
        {
          text: 'Status',
          align: 'start',
          value: 'enabled'
        }
      ]
    }
  },
  computed: {
    actions () {
      return [
        {
          label: 'Adicionar matéria',
          action: this.openDialogAddSubject,
          icon: 'mdi-plus',
          disabled: !this.subjectsCreate
        }
      ]
    }
  },
  created () {
    this.fetchAll()
  },
  methods: {
    fetchAll () {
      this.getSubject()
      this.subjectsView = utils.checkPermissions('subjects.view-any')
      this.subjectsCreate = utils.checkPermissions('subjects.create')
    },
    async getSubject () {
      this.loading = true
      this.loadingMsg = 'Carregando Lista...'
      try {
        const content = await this.$api.getSubjects()
        this.contents = content
        this.contents = this.contents.map(function (item) {
          if (item.enabled === 1) {
            item.enabled = 'Ativo'
          } else {
            item.enabled = 'Inativo'
          }
          return item
        })
      } catch {
        // console.log('Erro ao listar Segmentos')
      }
      this.loading = false
    },
    editSubject (event) {
      this.$router.push({ name: 'materias.id', params: { id: event.id } })
    },
    openDialogAddSubject () {
      this.renderComponent += 1
      this.dialogAddSubject = true
    },
    async newSubject (data) {
      this.request = true
      this.loadingMsg = 'Salvando...'
      try {
        await this.$api.newSubject(data)
        this.request = false
        this.dialogAddSubject = false
        this.snackbar = true
        this.msgSnackbar = 'Salvo com sucesso'
        this.fetchAll()
      } catch (e) {
        this.snackbar = true
        this.msgSnackbar = 'Não foi possível salvar, tente novamente mais tarde'
        // console.log(e)
      }
      this.request = false
    }
  }
}
</script>
