<template>
  <ItemPage
    title="Avaliações AUDE"
    size="lg"
    :actions="actions"
  >
    <template v-if="questionsView">
      <v-simple-table fixed-header>
        <Load v-if="loading" />
        <div
          v-else
        >
          <v-data-table
            :headers="headers"
            :items="audeExams"
            :items-per-page="15"
            :search="search"
            item-key="id"
            @click:row="goToAudeExam($event)"
          >
            <template #item.grade_level_id="{ item }">
              {{ gradeLevelName(item.grade_level_id) }}
            </template>
            <template #item.bimester="{ item }">
              {{ volumeName(item.bimester) }}
            </template>
            <template #item.starts_at="{ item }">
              {{ dayjs(item.starts_at).utc(true).local().format('DD/MM/YYYY HH:mm') }}
            </template>
            <template #item.ends_at="{ item }">
              {{ dayjs(item.ends_at).utc(true).local().format('DD/MM/YYYY HH:mm') }}
            </template>
            <template #item.is_published="{ item }">
              {{ item.is_published? 'Publicada' : 'Despublicada' }}
            </template>
          </v-data-table>
        </div>
      </v-simple-table>
      <DialogNewExam
        v-model="dialogNewExam"
        @close="dialogNewExam = false"
        @reload="getAudeExamList"
      />
    </template>
    <template v-else>
      usuário sem permissão
    </template>
  </ItemPage>
</template>

<script>
import handlesServerValidation from '@/mixins/handles-server-validation.js'
import ItemPage from '@/components/pages/ItemPage.vue'

import dayjs from '@/dayjs'
import DialogNewExam from './Dialog_New_Exam.vue'
import Load from '@/components/Loading.vue'
import { getTextForDisplay as volumeName } from '@/enums/volumeAudeExam'
import { getTextForDisplay as gradeLevelName } from '@/enums/gradeLevelAudeExam'
import utils from '@/api/utils.js'

export default {
  components: {
    DialogNewExam,
    ItemPage,
    Load
  },

  mixins: [
    handlesServerValidation
  ],

  data () {
    return {
      questionsView: true,
      questionsCreate: true,
      loading: false,
      absolute: true,
      audeExams: [],
      dialogNewExam: false,
      renderComponent: 0,
      search: '',
      headers: [
        {
          text: 'Id',
          align: 'start',
          value: 'id'
        },
        {
          text: 'Nome',
          align: 'start',
          value: 'name'
        },
        {
          text: 'Ano de ensino',
          align: 'start',
          value: 'grade_level_id'
        },
        {
          text: 'Bimestre',
          align: 'start',
          value: 'bimester'
        },
        {
          text: 'Inicio',
          align: 'start',
          value: 'starts_at'
        },
        {
          text: 'Fim',
          align: 'start',
          value: 'ends_at'
        },
        {
          text: 'Status',
          align: 'start',
          value: 'is_published'
        }
      ]
    }
  },
  computed: {
    actions () {
      return [
        {
          label: 'Nova avaliação',
          action: this.openNewExamDialog,
          icon: 'mdi-plus',
          disabled: !this.questionsCreate
        }
      ]
    }
  },
  created () {
    this.fetchAll()
  },
  methods: {
    volumeName,
    gradeLevelName,
    fetchAll () {
      this.getAudeExamList()
      this.questionsView = utils.checkPermissions('questions.view-any')
      this.questionsCreate = utils.checkPermissions('questions.create')
    },

    async getAudeExamList () {
      this.loading = true
      try {
        const audeExams = await this.$api.ListAudeExams()
        this.audeExams = audeExams
      } catch (e) {
        this.$store.dispatch('setSnackBar', { text: 'Erro ao tentar listar avaliações.', color: 'red' })
        this.getErrors(e)
        this.loading = false
      }
      this.loading = false
    },

    goToAudeExam (event) {
      this.$router.push({ name: 'conteudos.avaliacao-aude.id', params: { id: event.id } })
    },

    openNewExamDialog () {
      this.renderComponent += 1
      this.dialogNewExam = true
    },
    dayjs
  }
}
</script>
