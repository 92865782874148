<template>
  <ItemPage
    title="Usuários do portal"
    size="md"
  >
    <v-container v-if="userView">
      <v-row>
        <v-col cols="9">
          <v-text-field
            v-model="search"
            label="Pesquisa"
            :rules="rules.search"
            required
            @keydown.enter.prevent="searchPortalUsers"
          />
        </v-col>
        <v-col cols="3">
          <v-btn
            :disabled="search.length > 2? false : true"
            @click="searchPortalUsers()"
          >
            Pesquisar
          </v-btn>
        </v-col>
      </v-row>
      <div>
        Pesquise usuários pelo nome ou email
      </div>
    </v-container>

    <v-simple-table
      v-if="userView"
      fixed-header
    >
      <div
        class="mr-5 ml-5"
      >
        <v-data-table
          :headers="headers"
          :items="users"
          :disable-pagination="true"
          hide-default-footer
          item-key="id"
          loading-text="Carregando Lista..."
          no-data-text="Nenhum usuario encontrado"
        >
          <template #item.name="{ item }">
            <router-link :to="{ name: 'portal.usuarios.id', params: { id: item.id } }">
              {{ item.name }}
            </router-link>
          </template>
          <template #item.status="{ item }">
            {{ statusText(item.status) }}
          </template>
        </v-data-table>
      </div>
    </v-simple-table>
    <template v-else>
      usuário sem permissão
    </template>
  </ItemPage>
</template>

<script>
import utils from '@/api/utils.js'
import ItemPage from '@/components/pages/ItemPage.vue'

export default {
  components: {
    ItemPage
  },
  data () {
    return {
      errors: [],
      userView: true,
      loading: false,
      loadingMsg: '',
      users: [],
      message: 'Utilize o campo de pesquisa acima para buscar usuários',
      status: '',
      search: '',
      btnDisable: true,
      headers: [
        {
          text: 'Nome',
          align: 'start',
          value: 'name'
        },
        {
          text: 'E-mail',
          align: 'start',
          value: 'email'
        },
        {
          text: 'Status',
          align: 'start',
          value: 'status'
        }
      ],
      rules: {
        search: [
          value => !!value || 'Pesquisa é obrigatória.',
          value => (value && value.length > 2) || 'Min 3 caracteres'
        ]
      }
    }
  },
  created () {
  },
  methods: {
    getErrors (data) {
      if (data.data.errors) {
        this.errors = utils.setErrorsData(data.data.errors)
      } else {
        throw (data)
      }
    },
    fetchAll () {
      this.userView = utils.checkPermissions('portal-users.view-any')
    },
    async searchPortalUsers () {
      this.loading = true
      this.loadingMsg = 'Carregando Lista...'
      try {
        const users = await this.$api.searchPortalUsers(this.search)
        this.users = users
      } catch {
        // console.log('Erro ao listar usuários')
      }
      this.loading = false
    },
    editUser (event) {
      this.$router.push({ name: 'portal.usuarios', params: { id: event.id } })
    },
    statusText (typevalue) {
      const status = this.$api.statusType()
      return status.find(element => element.value === typevalue).statusName
    }
  }
}
</script>
