<template>
  <ItemPage
    title="Usuário do Portal"
    size="sm"
    :actions="actions"
  >
    <div v-if="userUpdate">
      <div class="font-weight-bold mb-1">
        Redefinir Nome
      </div>
      <v-form ref="form">
        <v-text-field
          v-model="user.name"
          label="Nome"
          outlined
          @input="verifyDirty()"
        />
        <label>Status</label>
        <v-switch
          v-model="user.status"
          color="primary"
          :label="(user && user.status) ? 'Ativo' : 'Inativo'"
          @change="verifyDirty()"
        />
      </v-form>
    </div>

    <v-divider
      v-if="userUpdate"
      class="mb-8 my-4"
    />
    <div v-if="userUpdate">
      <div class="font-weight-bold mb-1">
        Redefinir Senha
      </div>
      <v-row justify="space-between">
        <v-col>
          <template>
            <div class="text-body-2 text--secondary">
              Essa opção permite que o usuário use uma senha temporária enviada por email para redefinir a sua senha do Portal.
            </div>
          </template>
        </v-col>
        <v-col
          cols="12"
          md="auto"
        >
          <v-btn
            :disabled="sendingPassword"
            class="mt-1"
            depressed
            @click="dialogConfirm = true"
          >
            <v-icon left>
              mdi-lock-plus
            </v-icon> {{ sendingPassword === true ? 'Enviando...' : msgButtonTempPass }}
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <template v-else>
      usuário sem permissão
    </template>

    <v-dialog
      v-model="dialogConfirm"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title>
          Enviar nova senha temporária
        </v-card-title>
        <v-card-text>
          <v-form
            ref="form"
          >
            Tem certeza que deseja enviar nova senha para este usuário?
          </v-form>
        </v-card-text>
        <v-card-text>
          <div class="d-flex justify-space-between">
            <v-btn
              color="primary"
              text
              @click="dialogConfirm = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="newTemporaryPass(user.id)"
            >
              Enviar
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogTempPass"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title>
          Senha temporária
        </v-card-title>
        <v-card-text>
          <v-form
            ref="form"
          >
            A senha temporária desse usuário é: <Strong>{{ tempPass }}</Strong>
          </v-form>
        </v-card-text>
        <v-card-text>
          <div class="d-flex justify-end">
            <v-btn
              color="primary"
              text
              @click="dialogTempPass = false"
            >
              Fechar
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="loading"
      width="500"
      persistent
    >
      <Load
        :loadingmsg="loadingMsg"
      />
    </v-dialog>
  </ItemPage>
</template>

<script>
import Load from '@/components/Loading.vue'
import utils from '@/api/utils.js'
import ItemPage from '@/components/pages/ItemPage.vue'
import { getTextForDisplay as getRoleName } from '@/enums/roleTypes'

export default {
  components: {
    Load,
    ItemPage
  },

  data () {
    return {
      user: [],
      currentUser: [],
      loading: false,
      loadingMsg: '',

      userUpdate: false,

      isClean: false,
      errors: [],
      update: false,

      dialogTempPass: false,
      tempPass: '',

      dialogConfirm: false,
      sendingPassword: false,
      msgButtonTempPass: 'Enviar nova senha'
    }
  },

  computed: {
    id () {
      return this.$route.params.id
    },
    actions () {
      return [
        {
          label: 'Salvar Mudanças',
          action: this.updateUser,
          icon: 'mdi-floppy',
          disabled: !this.isClean
        }
      ]
    }
  },

  created () {
    this.fetchAll()
  },

  methods: {
    async fetchAll () {
      await this.getUserId()
      this.userUpdate = utils.checkPermissions('users.update-any')
    },

    getErrors (data) {
      if (data.data.errors) {
        this.errors = utils.setErrorsData(data.data.errors)
      } else {
        throw (data)
      }
    },

    async getUserId () {
      this.loading = true
      this.loadingMsg = 'Carregando informações'
      try {
        const response = await this.$api.getPortalUser(this.id)
        this.user = response
        this.currentUser = utils.currentData(this.user)
      } catch (e) {
        this.loading = false
        this.getErrors(e.response)
      }
      this.loading = false
    },

    verifyDirty () {
      const update = utils.newData(this.user, this.currentUser)
      if (Object.keys(update).length > 0) {
        this.isClean = true
      } else {
        this.isClean = false
      }
    },

    validate () {
      return this.$refs.form.validate()
    },

    async updateUser () {
      this.loading = true
      this.loadingMsg = 'Salvando informações'
      const validate = await this.validate()
      if (validate === false) {
        return false
      }
      const data = utils.newData(this.user, this.currentUser)
      try {
        await this.$api.updatePortalUser(this.$route.params.id, data)
        this.getUserId()
        this.isClean = false
      } catch {
        // console.log('Erro ao salvar informações')
      }
      this.fetchAll()
    },

    async newTemporaryPass (id) {
      this.sendingPassword = true
      this.dialogConfirm = false
      try {
        const request = await this.$api.resetPortalUserPassword(id)
        this.tempPass = request.new_password
        this.dialogTempPass = true
        this.msgButtonTempPass = 'Senha enviada'
      } catch (e) {
        this.sendingPassword = false
        this.msgButtonTempPass = 'Tente novamente'
        // console.log(e.response)
      }
    },

    getRoleName
  }
}
</script>
