<template>
  <div>
    <template v-if="waiting">
      <v-btn
        color="primary"
        text
        @click="openDialogArticle"
      >
        <v-icon left>
          mdi-cloud-upload
        </v-icon> Enviar Capa
      </v-btn>
    </template>
    <template v-if="processing">
      Enviando capa do Artigo...
      <v-progress-linear
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="6"
      />
    </template>
    <template v-if="concluded">
      <v-icon
        left
        color="green darken-2"
      >
        mdi-cloud-check
      </v-icon> Capa do artigo enviada
    </template>
    <template v-if="failed">
      <div
        v-for="(msg, index) in failedMsg"
        :key="index"
      >
        <v-icon
          left
          color="red darken-1"
        >
          mdi-alert-circle-outline
        </v-icon>
        {{ msg }}
      </div>
      <v-btn
        class="ml-5 float-right"
        color="primary"
        text
        @click="openDialogArticle"
      >
        <v-icon left>
          mdi-cloud-upload
        </v-icon> Tentar novamente
      </v-btn>
    </template>

    <v-dialog
      v-model="dialogArticle"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title>
          Upload do Artigo
          <v-spacer />
          <v-btn
            icon
            @click="dialogArticle = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-file-input
            ref="file"
            v-model="files"
            accept=".png, .jpeg, .jpg"
            label="Enviar arquivo"
            @change="changeBtn"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="d-flex align-end mb-2"
            text="text"
            color="primary"
            :disabled="btnFile"
            @click="sendArticle"
          >
            <v-icon>
              mdi-plus
            </v-icon>
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import Load from '@/components/Loading.vue'

export default {
  components: {
    // Load
  },

  data: () => ({
    dialogArticle: false,
    files: [],
    btnFile: true,
    waiting: true,
    processing: false,
    concluded: false,
    failed: false,
    failedMsg: ['Ocorreu um erro no envio da capa do artigo'],
    idTemporaryFile: null
  }),
  methods: {
    openDialogArticle () {
      this.dialogArticle = true
    },
    changeBtn () {
      if (this.files) {
        this.btnFile = false
      } else {
        this.btnFile = true
      }
    },
    async sendArticle () {
      this.failed = false
      this.$emit('beginUpload')
      const formData = new FormData()
      formData.append('file', this.files)
      this.dialogArticle = false
      this.waiting = false
      this.processing = true
      try {
        const response = await this.$api.uploadCoverArticle(formData)
        this.idTemporaryFile = response
        this.$emit('upload', this.idTemporaryFile)
        this.processing = false
        this.concluded = true
      } catch (e) {
        const response = e.response
        if (response?.data?.errors?.file) {
          this.failedMsg = response.data.errors.file
        }
        this.$emit('uploadFail')
        this.processing = false
        this.failed = true
        // console.log(e.response)
      }
    }
  }
}
</script>
