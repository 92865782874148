<template>
  <ItemPage
    title="Segmento - Curso/Ano"
    size="lg"
    :actions="actions"
  >
    <v-form
      ref="form"
    >
      <v-container>
        <v-row>
          <v-col
            cols="6"
            class="mr-auto"
          >
            <v-text-field
              v-model="gradeLevel.name"
              :rules="rulesName"
              :disabled="!gradeLevelsUpdate"
              label="Nome"
              @input="checkUpdate"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="gradeLevel.short_name"
              :disabled="!gradeLevelsUpdate"
              :rules="[() => (sizes(gradeLevel.name) < 20 || sizes(gradeLevel.short_name) > 1) || 'Nome maior que 20 caracteres, inclua sigla(min. 2 caracteres)', () => (gradeLevel.short_name === null || gradeLevel.short_name === '' || sizes(gradeLevel.short_name) <21 && sizes(gradeLevel.short_name) > 1 ) || 'inclua sigla(min. 2, max 20 caracteres)']"
              :error-messages="formError.short_name"
              :counter="20"
              maxlength="20"
              label="Abreviatura"
              @input="checkUpdate"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-if="!update"
            cols="12 d-flex justify-end"
          >
            <v-btn
              :disabled="btnDisable"
              @click="updateGradeLevel"
            >
              Atualizar
            </v-btn>
            <v-btn
              :disabled="gradeLevelsDelete"
              class="ml-4"
              @click="dialogConfirm = true"
            >
              Deletar
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-if="topicsView"
            cols="12"
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquise pelo nome da matéria ou tópico"
              single-line
              hide-details
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-simple-table
      v-if="topicsView"
      fixed-header
    >
      <template v-if="loading">
        <Load :loadingmsg="loadingMsg" />
      </template>
      <div
        v-else
        class="mr-5 ml-5"
      >
        <v-data-table
          :headers="headers"
          :items="topics"
          :items-per-page="10"
          :search="search"
          item-key="id"
          @click:row="editTopic($event)"
        >
          <template #item.subjectName="{ item }">
            {{ item.subjectName }}
          </template>
          <template #item.volume_id="{ item }">
            {{ getTextForDisplay(item.volume_id) }}
          </template>
        </v-data-table>
      </div>
    </v-simple-table>
    <v-dialog
      v-model="dialogAddTopic"
      width="500"
      persistent
    >
      <v-card>
        <template v-if="request">
          <Load :loadingmsg="loadingMsg" />
        </template>

        <AddTopic
          v-model="shouldShowAddTopicDialog"
          title="Tópico"
          :subjects="subjects"
          :teaching-systems="teachingSystems"
          @close="dialogAddTopic = false"
          @save="newTopic($event)"
        />
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogConfirm"
      width="500"
      persistent
    >
      <v-card>
        <template v-if="request">
          <Load :loadingmsg="loadingMsg" />
        </template>
        <template v-else>
          <DialogConfirm
            :key="renderComponent"
            title="o Curso / Ano"
            :name="gradeLevel.name"
            @close="dialogConfirm = false"
            @confirm="deleteGradeLevel($event)"
          />
        </template>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
    >
      {{ msgSnackbar }}

      <template #action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </ItemPage>
</template>

<script>
import Load from '@/components/Loading.vue'
import AddTopic from '@/components/settings/Add_Topic.vue'
import DialogConfirm from '@/components/Dialog_confirm.vue'
import ItemPage from '@/components/pages/ItemPage.vue'
import utils from '@/api/utils.js'
import { getTextForDisplay } from '@/enums/volumeTypes'

export default {
  components: {
    Load,
    AddTopic,
    DialogConfirm,
    ItemPage
  },
  data () {
    return {
      topicsView: false,
      topicsCreate: false,
      gradeLevelsUpdate: false,
      gradeLevelsDelete: false,
      dialogConfirm: false,
      btnDisable: true,
      renderComponent: 0,
      rulesName: [
        value => !!value || 'Preenchimento obrigatório.',
        value => (value && value.length >= 3) || 'Min 3 caracteres'
      ],
      dialogAddTopic: false,
      loading: false,
      loadingSave: false,
      update: false,
      request: false,
      snackbar: false,
      msgSnackbar: '',
      timeout: 3000,
      currentGradeLevel: {},
      gradeLevel: {},
      topics: [],
      subjects: [],
      volumes: [],
      formError: [],
      search: '',
      headers: [
        {
          text: 'id',
          align: 'start',
          value: 'id'
        }, {
          text: 'Nome do tópico',
          align: 'start',
          value: 'name'
        }, {
          text: 'Matéria',
          align: 'start',
          value: 'subjectName'
        }, {
          text: 'Volume',
          align: 'start',
          value: 'volume_id'
        }, {
          text: 'Sistema de Ensino',
          align: 'start',
          value: 'teaching_system.name'
        }
      ],

      // Dialogs
      teachingSystems: [],
      shouldShowAddTopicDialog: false
    }
  },
  computed: {
    id () {
      return this.$route.params.id
    },

    actions () {
      return [
        {
          label: 'Adicionar Tópico',
          action: this.opendialogAddTopic,
          icon: 'mdi-floppy',
          disabled: !this.topicsCreate
        },
        {
          label: 'Atualizar',
          action: this.updateGradeLevel,
          icon: 'mdi-floppy',
          disabled: this.btnDisable
        },
        {
          label: 'Deletar',
          action: () => { this.dialogConfirm = true },
          icon: 'mdi-delete',
          disabled: !this.gradeLevelsDelete
        }
      ]
    }
  },
  created () {
    this.fetchAll()
  },
  methods: {
    getTextForDisplay,
    async fetchAll () {
      await this.getSubjects()
      await this.getGradeLevel()
      await this.fetchTeachingSystems()
      this.topicsView = utils.checkPermissions('topics.view-any')
      this.topicsCreate = utils.checkPermissions('topics.create')
      this.gradeLevelsUpdate = utils.checkPermissions('grade-levels.update-any')
      this.gradeLevelsDelete = utils.checkPermissions('grade-levels.delete')
    },
    async getSubjects () {
      try {
        this.subjects = await this.$api.getSubjects()
      } catch (e) {
        // console.log(e)
      }
    },
    async getGradeLevel () {
      this.loading = true
      this.loadingMsg = 'Carregando lista de tópicos...'
      try {
        const response = await this.$api.getGradeLevel(this.id)
        this.gradeLevel = response
        this.currentGradeLevel = utils.currentData(response)
        this.topics = response.topics
        this.topics = this.topics.map((item) => utils.newTopicList(item, this.subjects))
      } catch {
        // console.log('Erro ao obter levels')
      }
      this.loading = false
    },

    async fetchTeachingSystems () {
      this.loading = true
      this.loadingMsg = 'Carregando lista de tópicos...'
      const response = await this.$api.getTeachingSystems()
      this.teachingSystems = response
      this.loading = false
    },

    checkUpdate () {
      const data = utils.diffObjects(this.gradeLevel, this.currentGradeLevel)
      if (utils.size(data) > 0) {
        this.btnDisable = false
      } else {
        this.btnDisable = true
      }
      return data
    },
    editTopic (event) {
      this.$router.push({ name: 'topicos.id', params: { id: event.id } })
    },
    opendialogAddTopic () {
      this.renderComponent += 1
      this.dialogAddTopic = true
    },
    sizes (data) {
      return utils.size(data)
    },
    checkDelete () {
      this.renderComponent += 1
      this.dialogConfirm = true
    },

    async deleteGradeLevel () {
      this.request = true
      this.loadingMsg = 'Excluindo...'
      try {
        await this.$api.disableGradeLevel(this.id)
        this.request = false
        this.snackbar = true
        this.msgSnackbar = 'Excluido com sucesso'
        this.$router.back()
      } catch (e) {
        this.request = false
        this.snackbar = true
        this.msgSnackbar = 'Não foi possível Excluir, tente novamente mais tarde'
        // console.log(e)
      }
      this.request = false
    },
    async updateGradeLevel () {
      const validate = await this.validate()
      if (validate === false) {
        return false
      }
      this.request = true
      const data = this.checkUpdate()
      try {
        await this.$api.updateGradeLevel(this.id, data)
        this.request = false
        this.snackbar = true
        this.msgSnackbar = 'Atualizado com sucesso'
        this.fetchAll()
      } catch (e) {
        this.request = false
        this.snackbar = true
        this.msgSnackbar = 'Não foi possível atualizar, tente novamente mais tarde'
        // console.log(e)
      }
      this.request = false
    },
    validate () {
      return this.$refs.form.validate()
    },
    async newTopic (data) {
      this.request = true
      this.loadingMsg = 'Salvando...'
      try {
        await this.$api.newTopic(this.id, data)
        this.request = false
        this.dialogAddTopic = false
        this.snackbar = true
        this.msgSnackbar = 'Salvo com sucesso'
        this.fetchAll()
      } catch (e) {
        this.snackbar = true
        this.msgSnackbar = 'Não foi possível salvar, tente novamente mais tarde'
        // console.log(e)
      }
      this.request = false
    }
  }
}
</script>
