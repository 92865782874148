<template>
  <ItemPage
    :title="gradeLevel.name ? gradeLevel.name : ''"
    size="md"
  >
    <v-simple-table
      v-if="subjectsFromGradeLevelView"
      fixed-header
    >
      <template v-if="loading">
        <Load :loadingmsg="loadingMsg" />
      </template>
      <v-data-table
        v-else
        :headers="headers"
        :items="subjectsFromGradeLevel"
        hide-default-footer
        :items-per-page="15"
        item-key="id"
        @click:row="editSubject($event)"
      >
        <template #item.name="{ item }">
          <router-link :to="{ name: 'conteudos-v2.sistema-de-ensino.teachingSystemID.segmento.gradeLevelGroupID.ano.gradeLevelID.materia.subjectID', params: { subjectID: item.id } }">
            {{ item.name }}
          </router-link>
        </template>
      </v-data-table>
    </v-simple-table>
    <template v-else>
      usuário sem permissão
    </template>
  </ItemPage>
</template>

<script>
import Load from '@/components/Loading.vue'
import utils from '@/api/utils.js'
import ItemPage from '@/components/pages/ItemPage.vue'
import { getTextForDisplay } from '@/enums/volumeTypes'

export default {
  components: {
    Load,
    ItemPage
  },
  data () {
    return {
      subjectsFromGradeLevelView: false,
      loading: false,
      loadingSave: false,
      gradeLevel: {},
      subjectsFromGradeLevel: [],
      headers: [
        {
          text: 'id',
          align: 'start',
          value: 'id'
        }, {
          text: 'Nome',
          align: 'start',
          value: 'name'
        }
      ]
    }
  },
  computed: {
    teachingSystemID () {
      return this.$route.params.teachingSystemID
    },
    gradeLevelGroupID () {
      return this.$route.params.gradeLevelGroupID
    },
    gradeLevelID () {
      return this.$route.params.gradeLevelID
    },
    subjectID () {
      return this.$route.params.subjectID
    }
  },
  created () {
    this.fetchAll()
  },
  methods: {
    getTextForDisplay,
    async fetchAll () {
      this.subjectsFromGradeLevelView = utils.checkPermissions('topics.view-any')
      await this.getGradeLevel()
      await this.getSubjectsOfGradeLevel()
    },
    async getGradeLevel () {
      this.loading = true
      this.loadingMsg = 'Carregando lista de cursos...'
      try {
        this.gradeLevel = await this.$api.getGradeLevel(this.gradeLevelID)
      } catch {
        // console.log('Erro ao obter os cursos')
      }
      this.loading = false
    },
    async getSubjectsOfGradeLevel () {
      this.loading = true
      this.loadingMsg = 'Carregando lista de matérias...'
      try {
        this.subjectsFromGradeLevel = await this.$api.getSubjectsOfGradeLevel(this.gradeLevelID, this.teachingSystemID)
      } catch {
        // console.log('Erro ao obter matérias')
      }
      this.loading = false
    },
    editSubject (event) {
      this.$router.push({ name: 'conteudos-v2.sistema-de-ensino.teachingSystemID.segmento.gradeLevelGroupID.ano.gradeLevelID.materia.subjectID.volume.volumeID.topico', params: { subjectID: event.id } })
    }
  }
}
</script>
