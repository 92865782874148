/* eslint-disable */
/* DO NOT EDIT. This file is automatically generated. */
import bancas__id from '@/pages/bancas/[id]/page.vue';
import bancas from '@/pages/bancas/page.vue';
import cargos__id from '@/pages/cargos/[id]/page.vue';
import cargos from '@/pages/cargos/page.vue';
import conteudos__artigos__id from '@/pages/conteudos/artigos/[id]/page.vue';
import conteudos__artigos__novo from '@/pages/conteudos/artigos/novo/page.vue';
import conteudos__artigos from '@/pages/conteudos/artigos/page.vue';
import conteudos__avaliacao_aude__id from '@/pages/conteudos/avaliacao-aude/[id]/page.vue';
import conteudos__avaliacao_aude__grupo_de_questoes__id__status__gradelevel from '@/pages/conteudos/avaliacao-aude/grupo-de-questoes/[id]/[status]/[gradelevel]/page.vue';
import conteudos__avaliacao_aude__grupo_de_questoes__id__status__gradelevel__questao from '@/pages/conteudos/avaliacao-aude/grupo-de-questoes/[id]/[status]/[gradelevel]/questao/page.vue';
import conteudos__avaliacao_aude from '@/pages/conteudos/avaliacao-aude/page.vue';
import conteudos__materiais__id from '@/pages/conteudos/materiais/[id]/page.vue';
import conteudos__materiais__novo from '@/pages/conteudos/materiais/novo/page.vue';
import conteudos__materiais from '@/pages/conteudos/materiais/page.vue';
import conteudos__midias__id from '@/pages/conteudos/midias/[id]/page.vue';
import conteudos__midias from '@/pages/conteudos/midias/page.vue';
import conteudos__questoes__id from '@/pages/conteudos/questoes/[id]/page.vue';
import conteudos__questoes__criar from '@/pages/conteudos/questoes/criar/page.vue';
import conteudos__questoes__editar__id from '@/pages/conteudos/questoes/editar/[id]/page.vue';
import conteudos__questoes from '@/pages/conteudos/questoes/page.vue';
import conteudos_v2__sistema_de_ensino__teachingSystemID__segmento__gradeLevelGroupID__ano__gradeLevelID__materia__subjectID from '@/pages/conteudos-v2/sistema-de-ensino/[teachingSystemID]/segmento/[gradeLevelGroupID]/ano/[gradeLevelID]/materia/[subjectID]/page.vue';
import conteudos_v2__sistema_de_ensino__teachingSystemID__segmento__gradeLevelGroupID__ano__gradeLevelID__materia__subjectID__volume__volumeID__topico__topicID from '@/pages/conteudos-v2/sistema-de-ensino/[teachingSystemID]/segmento/[gradeLevelGroupID]/ano/[gradeLevelID]/materia/[subjectID]/volume/[volumeID]/topico/[topicID]/page.vue';
import conteudos_v2__sistema_de_ensino__teachingSystemID__segmento__gradeLevelGroupID__ano__gradeLevelID__materia from '@/pages/conteudos-v2/sistema-de-ensino/[teachingSystemID]/segmento/[gradeLevelGroupID]/ano/[gradeLevelID]/materia/page.vue';
import conteudos_v2__sistema_de_ensino__teachingSystemID__segmento__gradeLevelGroupID__ano from '@/pages/conteudos-v2/sistema-de-ensino/[teachingSystemID]/segmento/[gradeLevelGroupID]/ano/page.vue';
import conteudos_v2__sistema_de_ensino__teachingSystemID__segmento from '@/pages/conteudos-v2/sistema-de-ensino/[teachingSystemID]/segmento/page.vue';
import conteudos_v2__sistema_de_ensino from '@/pages/conteudos-v2/sistema-de-ensino/page.vue';
import curso__id from '@/pages/curso/[id]/page.vue';
import escolas__id from '@/pages/escolas/[id]/page.vue';
import escolas from '@/pages/escolas/page.vue';
import materias__id from '@/pages/materias/[id]/page.vue';
import materias from '@/pages/materias/page.vue';
import index from '@/pages/page.vue';
import painel from '@/pages/painel/page.vue';
import portal__usuarios__id from '@/pages/portal/usuarios/[id]/page.vue';
import portal__usuarios from '@/pages/portal/usuarios/page.vue';
import segmentos__id from '@/pages/segmentos/[id]/page.vue';
import segmentos from '@/pages/segmentos/page.vue';
import tag_type__id from '@/pages/tag-type/[id]/page.vue';
import tag_type from '@/pages/tag-type/page.vue';
import tags__id from '@/pages/tags/[id]/page.vue';
import tags from '@/pages/tags/page.vue';
import topicos__id from '@/pages/topicos/[id]/page.vue';
import usuarios__id from '@/pages/usuarios/[id]/page.vue';
import usuarios from '@/pages/usuarios/page.vue';

export default [
  {
    path: '/bancas/:id',
    name: 'bancas.id',
    component: bancas__id
  },
  {
    path: '/bancas',
    name: 'bancas',
    component: bancas
  },
  {
    path: '/cargos/:id',
    name: 'cargos.id',
    component: cargos__id
  },
  {
    path: '/cargos',
    name: 'cargos',
    component: cargos
  },
  {
    path: '/conteudos/artigos/:id',
    name: 'conteudos.artigos.id',
    component: conteudos__artigos__id
  },
  {
    path: '/conteudos/artigos/novo',
    name: 'conteudos.artigos.novo',
    component: conteudos__artigos__novo
  },
  {
    path: '/conteudos/artigos',
    name: 'conteudos.artigos',
    component: conteudos__artigos
  },
  {
    path: '/conteudos/avaliacao-aude/:id',
    name: 'conteudos.avaliacao-aude.id',
    component: conteudos__avaliacao_aude__id
  },
  {
    path: '/conteudos/avaliacao-aude/grupo-de-questoes/:id/:status/:gradelevel',
    name: 'conteudos.avaliacao-aude.grupo-de-questoes.id.status.gradelevel',
    component: conteudos__avaliacao_aude__grupo_de_questoes__id__status__gradelevel
  },
  {
    path: '/conteudos/avaliacao-aude/grupo-de-questoes/:id/:status/:gradelevel/questao',
    name: 'conteudos.avaliacao-aude.grupo-de-questoes.id.status.gradelevel.questao',
    component: conteudos__avaliacao_aude__grupo_de_questoes__id__status__gradelevel__questao
  },
  {
    path: '/conteudos/avaliacao-aude',
    name: 'conteudos.avaliacao-aude',
    component: conteudos__avaliacao_aude
  },
  {
    path: '/conteudos/materiais/:id',
    name: 'conteudos.materiais.id',
    component: conteudos__materiais__id
  },
  {
    path: '/conteudos/materiais/novo',
    name: 'conteudos.materiais.novo',
    component: conteudos__materiais__novo
  },
  {
    path: '/conteudos/materiais',
    name: 'conteudos.materiais',
    component: conteudos__materiais
  },
  {
    path: '/conteudos/midias/:id',
    name: 'conteudos.midias.id',
    component: conteudos__midias__id
  },
  {
    path: '/conteudos/midias',
    name: 'conteudos.midias',
    component: conteudos__midias
  },
  {
    path: '/conteudos/questoes/:id',
    name: 'conteudos.questoes.id',
    component: conteudos__questoes__id
  },
  {
    path: '/conteudos/questoes/criar',
    name: 'conteudos.questoes.criar',
    component: conteudos__questoes__criar
  },
  {
    path: '/conteudos/questoes/editar/:id',
    name: 'conteudos.questoes.editar.id',
    component: conteudos__questoes__editar__id
  },
  {
    path: '/conteudos/questoes',
    name: 'conteudos.questoes',
    component: conteudos__questoes
  },
  {
    path: '/conteudos-v2/sistema-de-ensino/:teachingSystemID/segmento/:gradeLevelGroupID/ano/:gradeLevelID/materia/:subjectID',
    name: 'conteudos-v2.sistema-de-ensino.teachingSystemID.segmento.gradeLevelGroupID.ano.gradeLevelID.materia.subjectID',
    component: conteudos_v2__sistema_de_ensino__teachingSystemID__segmento__gradeLevelGroupID__ano__gradeLevelID__materia__subjectID
  },
  {
    path: '/conteudos-v2/sistema-de-ensino/:teachingSystemID/segmento/:gradeLevelGroupID/ano/:gradeLevelID/materia/:subjectID/volume/:volumeID/topico/:topicID',
    name: 'conteudos-v2.sistema-de-ensino.teachingSystemID.segmento.gradeLevelGroupID.ano.gradeLevelID.materia.subjectID.volume.volumeID.topico.topicID',
    component: conteudos_v2__sistema_de_ensino__teachingSystemID__segmento__gradeLevelGroupID__ano__gradeLevelID__materia__subjectID__volume__volumeID__topico__topicID
  },
  {
    path: '/conteudos-v2/sistema-de-ensino/:teachingSystemID/segmento/:gradeLevelGroupID/ano/:gradeLevelID/materia',
    name: 'conteudos-v2.sistema-de-ensino.teachingSystemID.segmento.gradeLevelGroupID.ano.gradeLevelID.materia',
    component: conteudos_v2__sistema_de_ensino__teachingSystemID__segmento__gradeLevelGroupID__ano__gradeLevelID__materia
  },
  {
    path: '/conteudos-v2/sistema-de-ensino/:teachingSystemID/segmento/:gradeLevelGroupID/ano',
    name: 'conteudos-v2.sistema-de-ensino.teachingSystemID.segmento.gradeLevelGroupID.ano',
    component: conteudos_v2__sistema_de_ensino__teachingSystemID__segmento__gradeLevelGroupID__ano
  },
  {
    path: '/conteudos-v2/sistema-de-ensino/:teachingSystemID/segmento',
    name: 'conteudos-v2.sistema-de-ensino.teachingSystemID.segmento',
    component: conteudos_v2__sistema_de_ensino__teachingSystemID__segmento
  },
  {
    path: '/conteudos-v2/sistema-de-ensino',
    name: 'conteudos-v2.sistema-de-ensino',
    component: conteudos_v2__sistema_de_ensino
  },
  {
    path: '/curso/:id',
    name: 'curso.id',
    component: curso__id
  },
  {
    path: '/escolas/:id',
    name: 'escolas.id',
    component: escolas__id
  },
  {
    path: '/escolas',
    name: 'escolas',
    component: escolas
  },
  {
    path: '/materias/:id',
    name: 'materias.id',
    component: materias__id
  },
  {
    path: '/materias',
    name: 'materias',
    component: materias
  },
  {
    path: '/',
    name: 'index',
    component: index
  },
  {
    path: '/painel',
    name: 'painel',
    component: painel
  },
  {
    path: '/portal/usuarios/:id',
    name: 'portal.usuarios.id',
    component: portal__usuarios__id
  },
  {
    path: '/portal/usuarios',
    name: 'portal.usuarios',
    component: portal__usuarios
  },
  {
    path: '/segmentos/:id',
    name: 'segmentos.id',
    component: segmentos__id
  },
  {
    path: '/segmentos',
    name: 'segmentos',
    component: segmentos
  },
  {
    path: '/tag-type/:id',
    name: 'tag-type.id',
    component: tag_type__id
  },
  {
    path: '/tag-type',
    name: 'tag-type',
    component: tag_type
  },
  {
    path: '/tags/:id',
    name: 'tags.id',
    component: tags__id
  },
  {
    path: '/tags',
    name: 'tags',
    component: tags
  },
  {
    path: '/topicos/:id',
    name: 'topicos.id',
    component: topicos__id
  },
  {
    path: '/usuarios/:id',
    name: 'usuarios.id',
    component: usuarios__id
  },
  {
    path: '/usuarios',
    name: 'usuarios',
    component: usuarios
  }
];
