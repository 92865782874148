<template>
  <ItemPage
    :title="loading? 'Avaliação' : oldform.name"
    size="md"
    :actions="actions"
  >
    <v-progress-linear
      v-if="loading"
      color="amber"
      indeterminate
    />
    <v-alert
      v-if="published"
      dense
      type="info"
    >
      Esta avaliação está <strong>Publicada </strong> e por isso não pode ser <strong>atualizada</strong> ou <strong>excluida</strong>
    </v-alert>
    <v-form
      ref="form"
    >
      <v-container>
        <v-row>
          <v-col
            cols="6"
            class="mr-auto"
          >
            <v-text-field
              v-model="name"
              prepend-icon="mdi-note-text"
              :error-messages="formErrors.name"
              :rules="rulesName"
              :disabled="questionsUpdate? questionsUpdate : disableForm? disableForm : loading"
              label="Nome da avaliação"
              @input="formErrors.name = ''"
            />
          </v-col>
          <v-col cols="6">
            <v-menu
              ref="menuDate"
              v-model="menuDate"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  label="Data da avaliação"
                  prepend-icon="mdi-calendar"
                  :error-messages="formErrors.starts_at ? formErrors.starts_at : formErrors.ends_at ? formErrors.ends_at : null"
                  readonly
                  :disabled="questionsUpdate? questionsUpdate : disableForm? disableForm : loading"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="date"
                :disabled="questionsUpdate? questionsUpdate : disableForm? disableForm : loading"
                :min="tomorrow"
                no-title
                scrollable
              >
                <v-spacer />
                <v-btn
                  text
                  color="primary"
                  @click="menuDate = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menuDate.save(date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6">
            <v-menu
              ref="menuTimeStart"
              v-model="menuTimeStart"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="timeStart"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  v-model="timeStart"
                  label="Horário para inicio da avaliação"
                  prepend-icon="mdi-clock-time-four-outline"
                  :rules="rulesTimeStart"
                  :error-messages="formErrors.starts_at"
                  readonly
                  :disabled="questionsUpdate? questionsUpdate : disableForm? disableForm : loading"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-time-picker
                v-if="menuTimeStart"
                v-model="timeStart"
                format="24hr"
                :max="timeEnd"
                full-width
              >
                <v-spacer />
                <v-btn
                  text
                  color="primary"
                  @click="menuTimeStart = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menuTimeStart.save(timeStart)"
                >
                  OK
                </v-btn>
              </v-time-picker>
            </v-menu>
          </v-col>
          <v-col cols="6">
            <v-menu
              ref="menuTimeEnd"
              v-model="menuTimeEnd"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="timeEnd"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  v-model="timeEnd"
                  label="Horário para o fim da avaliação"
                  prepend-icon="mdi-clock-time-four-outline"
                  :rules="rulesTimeEnd"
                  :error-messages="formErrors.ends_at"
                  readonly
                  :disabled="questionsUpdate? questionsUpdate : disableForm? disableForm : loading"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-time-picker
                v-if="menuTimeEnd"
                v-model="timeEnd"
                format="24hr"
                :min="timeStart"
                full-width
              >
                <v-spacer />
                <v-btn
                  text
                  color="primary"
                  @click="menuTimeEnd = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menuTimeEnd.save(timeEnd)"
                >
                  OK
                </v-btn>
              </v-time-picker>
            </v-menu>
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="grade_level_id"
              :disabled="questionsUpdate? questionsUpdate : disableForm? disableForm : loading"
              :items="gradeLevels"
              :rules="rulesGradeLevel"
              prepend-icon="mdi-school"
              label="Ano do Ensino Médio"
              item-text="name"
              item-value="id"
            />
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="bimester"
              :items="bimesters"
              :disabled="questionsUpdate? questionsUpdate : disableForm? disableForm : loading"
              :rules="rulesBimesters"
              prepend-icon="mdi-calendar"
              item-text="name"
              item-value="id"
              label="Bimestre"
            />
          </v-col>
          <v-col>
            <v-autocomplete
              v-model="selectedInstitutions"
              :items="institutions"
              :disabled="questionsUpdate? questionsUpdate : disableForm? disableForm : loading"
              prepend-icon="mdi-home-city-outline"
              label="Instituições"
              item-text="code"
              item-value="id"
              multiple
            >
              <template #item="{ item }">
                {{ item.code }} - {{ item.name }}
              </template>
              <template #selection="{ item, select }">
                <v-chip
                  small
                  close
                  @click="select"
                  @click:close="selectedInstitutions.splice(selectedInstitutions.indexOf(item.id), 1)"
                >
                  {{ item.code }} - {{ item.name }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-switch
              v-model="foreign_language"
              color="primary"
              :label="foreign_language ? 'Contem língua estrangeira' : 'Não contem língua estrangeira' "
              :disabled="questionsUpdate ? questionsUpdate : disableForm ? disableForm : loading"
              :value="foreign_language"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-simple-table
      v-if="questionsView"
      fixed-header
    >
      <v-progress-linear
        v-if="loadingList"
        color="amber"
        indeterminate
      />
      <div
        v-else
        class="mr-5 ml-5"
      >
        <v-data-table
          :headers="headers"
          :items="questionsGroup"
          :items-per-page="10"
          item-key="id"
          @click:row="goToQuestionGroup($event)"
        />
      </div>
    </v-simple-table>

    <DialogNewQuestionGroup
      v-model="dialogNewQuestionGroup"
      :id-exam="parseInt(id)"
      @close="dialogNewQuestionGroup = false"
      @reload="ListQuestionsGroups"
    />

    <DialogConfirm
      v-model="dialogConfirm"
      title="a avaliação"
      action="Excluir"
      :name="name"
      @close="dialogConfirm = false"
      @confirm="deleteAudeExam($event)"
    />
  </ItemPage>
</template>

<script>
import handlesServerValidation from '@/mixins/handles-server-validation.js'
import ItemPage from '@/components/pages/ItemPage.vue'
import dayjs from '@/dayjs'
import DialogNewQuestionGroup from './Dialog_New_Question_Group.vue'
import DialogConfirm from '@/components/Dialog_confirm_action.vue'
import { listTypes as listVolumeTypes } from '@/enums/volumeAudeExam'
import { listTypes as listGradeLevelTypes } from '@/enums/gradeLevelAudeExam'
import utils from '@/api/utils.js'

export default {
  components: {
    DialogNewQuestionGroup,
    ItemPage,
    DialogConfirm
  },

  mixins: [
    handlesServerValidation
  ],

  data () {
    return {
      questionsView: false,
      questionsCreate: false,
      questionsUpdate: false,
      questionsDelete: false,
      name: null,
      published: false,
      foreign_language: false,
      date: null,
      menuDate: false,
      timeStart: null,
      menuTimeStart: false,
      timeEnd: null,
      menuTimeEnd: false,
      oldform: {
        name: null,
        published: false,
        foreign_language: false,
        date: null,
        timeStart: null,
        timeEnd: null
      },
      tomorrow: new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().substr(0, 10),
      rulesName: [
        value => !!value || 'Nome é obrigatório.',
        value => (value && value.length >= 4) || 'Min 3 caracteres',
        value => (value && value.length <= 250) || 'Max 250 caracteres'
      ],
      rulesTimeStart: [
        value => !!value || 'Horário inicial é obrigatório.'
      ],
      rulesTimeEnd: [
        value => !!value || 'Horário final é obrigatório.'
      ],
      rulesGradeLevel: [
        value => !!value || 'Escolher um ano é obrigatório.'
      ],
      rulesBimesters: [
        value => !!value || 'Escolher um Bimestre é obrigatório.'
      ],
      audeExam: [],
      grade_level_id: null,
      gradeLevels: [],
      bimester: null,
      bimesters: [],
      institutions: [],
      selectedInstitutions: [],
      dialogNewQuestionGroup: false,
      dialogConfirm: false,
      disableUpdate: true,
      loading: false,
      loadingList: false,

      newQuestionGroup: false,

      questionsGroup: [],
      headers: [
        {
          text: 'id',
          align: 'start',
          value: 'id'
        },
        {
          text: 'Área do conhecimento',
          align: 'start',
          value: 'title'
        },
        {
          text: 'Disciplinas',
          align: 'start',
          value: 'caption'
        }
      ]
    }
  },
  computed: {
    id () {
      return this.$route.params.id
    },

    disableForm () {
      return this.published
    },

    actions () {
      return [
        {
          label: 'Atualizar',
          action: this.updateAudeExam,
          icon: 'mdi-floppy',
          disabled: this.questionsUpdate ? this.questionsUpdate : this.disableForm ? this.disableForm : this.disableUpdate ? this.disableUpdate : this.loading
        },
        {
          label: this.published ? 'despublicar' : 'Publicar',
          action: this.publish,
          icon: this.published ? 'mdi-close' : 'mdi-check',
          disabled: this.questionsUpdate ? this.questionsUpdate : this.loading
        },
        {
          label: 'Nova área do conhecimento',
          action: this.openNewQuestionGroup,
          icon: 'mdi-plus',
          disabled: this.questionsUpdate ? this.questionsUpdate : this.disableForm ? this.disableForm : this.loading
        },
        {
          label: 'Deletar',
          action: () => { this.dialogConfirm = true },
          icon: 'mdi-delete',
          disabled: this.questionsDelete ? this.questionsDelete : this.disableForm ? this.disableForm : this.loading
        }
      ]
    }
  },
  watch: {
    name () {
      this.checkUpdate()
    },
    foreign_language () {
      if (!this.foreign_language) {
        this.foreign_language = false
      }
      this.checkUpdate()
    },
    date () {
      this.checkUpdate()
    },
    timeStart () {
      this.checkUpdate()
    },
    timeEnd () {
      this.checkUpdate()
    },
    grade_level_id () {
      this.checkUpdate()
    },
    bimester () {
      this.checkUpdate()
    },
    selectedInstitutions () {
      this.checkUpdate()
    }
  },
  created () {
    this.fetchAll()
    this.bimesters = listVolumeTypes()
    this.gradeLevels = listGradeLevelTypes()
  },
  methods: {

    listVolumeTypes,
    listGradeLevelTypes,
    async fetchAll () {
      await this.getAudeExam()
      // await this.fetchGradeLevelGroups()
      this.questionsView = utils.checkPermissions('questions.view-any')
      this.questionsCreate = utils.checkPermissions('questions.create')
      this.questionsUpdate = !utils.checkPermissions('grade-level-groups.update-any')
      this.questionsDelete = !utils.checkPermissions('grade-level-groups.delete')
      await this.ListQuestionsGroups()
      this.institutions = await this.$api.getSchool()
    },

    async getAudeExam () {
      this.loading = true
      try {
        const response = await this.$api.getAudeExam(this.id)
        this.audeExam = response
        await this.formBuild()
      } catch (e) {
        this.$store.dispatch('setSnackBar', { text: 'Erro ao tentar obter avaliação.', color: 'red' })
        this.loading = false
        this.getErrors(e)
      }
    },

    async formBuild () {
      this.name = this.audeExam?.name
      this.published = this.audeExam?.is_published
      this.foreign_language = Boolean(this.audeExam?.has_foreign_language)
      this.date = dayjs.utc(this.audeExam?.starts_at).local().format('YYYY-MM-DD')
      this.timeStart = dayjs.utc(this.audeExam?.starts_at).local().format('HH:mm')
      this.timeEnd = dayjs.utc(this.audeExam?.ends_at).local().format('HH:mm')
      this.grade_level_id = this.audeExam?.grade_level_id
      this.bimester = this.audeExam?.bimester
      this.selectedInstitutions = this.audeExam?.institutions_ids

      this.oldform.name = this.audeExam?.name
      this.oldform.foreign_language = Boolean(this.audeExam?.has_foreign_language)
      this.oldform.date = dayjs.utc(this.audeExam?.starts_at).local().format('YYYY-MM-DD')
      this.oldform.timeStart = dayjs.utc(this.audeExam?.starts_at).local().format('HH:mm')
      this.oldform.timeEnd = dayjs.utc(this.audeExam?.ends_at).local().format('HH:mm')
      this.oldform.grade_level_id = this.audeExam?.grade_level_id
      this.oldform.bimester = this.audeExam?.bimester
      this.oldform.institutions_ids = this.audeExam?.institutions_ids
      this.loading = false
    },
    async checkUpdate () {
      const newdata = {
        name: this.name,
        date: this.date,
        foreign_language: this.foreign_language,
        timeStart: this.timeStart,
        timeEnd: this.timeEnd,
        grade_level_id: this.grade_level_id,
        bimester: this.bimester,
        institutions_ids: this.selectedInstitutions
      }
      const data = await utils.newData(newdata, this.oldform)
      if (utils.size(data) > 0) {
        this.disableUpdate = false
      } else {
        this.disableUpdate = true
      }
      return data
    },

    async publish () {
      this.loading = true
      try {
        if (this.audeExam.is_published) {
          await this.$api.unpublishAudeExam(this.id)
          this.$store.dispatch('setSnackBar', { text: 'Avaliação despublicada com sucesso.' })
        } else {
          await this.$api.publishAudeExam(this.id)
          this.$store.dispatch('setSnackBar', { text: 'Avaliação publicada com sucesso.' })
        }
        await this.getAudeExam()
        this.loading = false
      } catch (e) {
        const error = e?.response?.data?.message
        if (error) {
          this.$store.dispatch('setSnackBar', { text: error, color: 'red' })
        } else {
          this.$store.dispatch('setSnackBar', { text: 'Erro ao tentar publicar avaliação.', color: 'red' })
        }
        this.loading = false
      }
    },
    validate () {
      return this.$refs.form.validate()
    },
    async updateAudeExam () {
      const validate = await this.validate()
      if (!validate) {
        return false
      }
      if (this.audeExam.is_published) {
        this.$store.dispatch('setSnackBar', { text: 'Não pode atualizar uma avaliação publicada.', color: 'red' })
        return false
      }
      this.loading = true
      const data = {
        name: this.name,
        has_foreign_language: this.foreign_language,
        starts_at: this.date + ' ' + this.timeStart + ':00',
        ends_at: this.date + ' ' + this.timeEnd + ':00',
        grade_level_id: this.grade_level_id,
        bimester: this.bimester,
        institutions_ids: this.selectedInstitutions
      }
      try {
        await this.$api.updateAudeExam(this.id, data)
        this.loading = false
        this.$store.dispatch('setSnackBar', { text: 'Avaliação atualizada com sucesso.' })
        await this.getAudeExam()
        this.checkUpdate()
      } catch (e) {
        this.$store.dispatch('setSnackBar', { text: 'Erro ao tentar atualizar avaliação.', color: 'red' })
        this.loading = false
        this.getErrors(e)
      }
    },
    async deleteAudeExam () {
      this.loading = true
      try {
        await this.$api.deleteAudeExam(this.id)
        this.$store.dispatch('setSnackBar', { text: 'Avaliação excluída com sucesso.' })
        this.loading = false
        this.$router.go(-1)
      } catch (e) {
        this.$store.dispatch('setSnackBar', { text: 'Erro ao tentar excluir avaliação.', color: 'red' })
        this.loading = false
      }
    },

    async ListQuestionsGroups () {
      this.loadingList = true
      try {
        const questionsGroup = await this.$api.ListQuestionsGroups(this.id)
        this.questionsGroup = questionsGroup
      } catch (e) {
        this.$store.dispatch('setSnackBar', { text: 'Erro ao tentar listar grupos de questões.', color: 'red' })
        this.getErrors(e)
        this.loadingList = false
      }
      this.loadingList = false
    },

    openNewQuestionGroup () {
      this.dialogNewQuestionGroup = true
    },

    goToQuestionGroup (event) {
      let disabled = parseInt(1)
      if (!this.disableForm) {
        disabled = parseInt(0)
      }
      this.$router.push({ name: 'conteudos.avaliacao-aude.grupo-de-questoes.id.status.gradelevel', params: { id: event.id, status: disabled, gradelevel: this.grade_level_id } })
    },
    dayjs
  }
}
</script>
