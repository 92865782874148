// import { Node, Plugin } from 'tiptap'
import { Node } from 'tiptap'
import { nodeInputRule } from 'tiptap-commands'

/**
 * Matches following attributes in Markdown-typed image: [, alt, src, title]
 *
 * Example:
 * ![Lorem](image.jpg) -> [, "Lorem", "image.jpg"]
 * ![](image.jpg "Ipsum") -> [, "", "image.jpg", "Ipsum"]
 * ![Lorem](image.jpg "Ipsum") -> [, "Lorem", "image.jpg", "Ipsum"]
 */
const IMAGE_INPUT_REGEX = /!\[(.+|:?)\]\((\S+)(?:(?:\s+)["'](\S+)["'])?\)/

export default class Image extends Node {
  constructor (name, parent, uploadFunc = null) {
    super(name, parent)
    this.uploadFunc = uploadFunc
  }

  get name () {
    return 'aude_question_image'
  }

  get schema () {
    return {
      inline: true,
      attrs: {
        src: {},
        alt: {
          default: null
        },
        title: {
          default: null
        },
        audeQuestionImageId: {}
      },
      group: 'inline',
      draggable: true,
      parseDOM: [
        {
          tag: 'img[data-aude-question-image-id]',
          getAttrs: dom => ({
            src: dom.getAttribute('src'),
            title: dom.getAttribute('title'),
            alt: dom.getAttribute('alt'),
            audeQuestionImageId: dom.getAttribute('data-aude-question-image-id')
          })
        }
      ],
      toDOM: function (node) {
        const domAttrs = {
          src: node.attrs.src,
          title: node.attrs.title,
          alt: node.attrs.alt,
          'data-aude-question-image-id': node.attrs.audeQuestionImageId
        }

        return ['img', domAttrs]
      }
    }
  }

  commands ({ type }) {
    return attrs => (state, dispatch) => {
      const { selection } = state
      const position = selection.$cursor ? selection.$cursor.pos : selection.$to.pos
      const transaction = state.tr.insert(position, type.create(attrs))
      dispatch(transaction)
    }
  }

  inputRules ({ type }) {
    return [
      nodeInputRule(IMAGE_INPUT_REGEX, type, match => {
        const [, alt, src, title] = match
        return {
          src,
          alt,
          title
        }
      })
    ]
  }

  // get plugins () {
  //   const upload = this.uploadFunc
  //   return [
  //     new Plugin({
  //       props: {
  //         handleDOMEvents: {
  //           drop (view, event) {
  //             const hasFiles = event.dataTransfer &&
  //                               event.dataTransfer.files &&
  //                               event.dataTransfer.files.length

  //             if (!hasFiles) {
  //               return
  //             }

  //             const images = Array
  //               .from(event.dataTransfer.files)
  //               .filter(file => (/image/i).test(file.type))

  //             if (images.length === 0) {
  //               return
  //             }

  //             event.preventDefault()

  //             const { schema } = view.state
  //             const coordinates = view.posAtCoords({ left: event.clientX, top: event.clientY })

  //             images.forEach(async image => {
  //               const reader = new FileReader()

  //               if (upload) {
  //                 const node = schema.nodes.image.create({
  //                   src: await upload(image)
  //                 })
  //                 const transaction = view.state.tr.insert(coordinates.pos, node)
  //                 view.dispatch(transaction)
  //               } else {
  //                 reader.onload = readerEvent => {
  //                   const node = schema.nodes.image.create({
  //                     src: readerEvent.target.result
  //                   })
  //                   const transaction = view.state.tr.insert(coordinates.pos, node)
  //                   view.dispatch(transaction)
  //                 }
  //                 reader.readAsDataURL(image)
  //               }
  //             })
  //           }
  //         }
  //       }
  //     })
  //   ]
  // }
}
