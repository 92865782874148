<template>
  <ItemPage
    title="Questões"
    size="md"
    :actions="actions"
  >
    <template
      v-if="questionsView"
    >
      <v-row>
        <v-col md="6">
          <v-select
            v-model="teachingSystem"
            label="Sistema de Ensino"
            item-text="name"
            item-value="id"
            :items="teachingSystems"
            :disabled="busy"
          />
        </v-col>

        <v-col md="6">
          <v-select
            v-model="gradeLevel"
            label="Segmento e ano"
            :items="gradeLevelGroupsAndLevels"
            :disabled="!teachingSystem || busy"
          />
        </v-col>
      </v-row>

      <v-row class="align-center">
        <v-col md="4">
          <v-select
            v-model="subject"
            label="Matéria"
            :items="subjects"
            :disabled="!gradeLevel || busy"
          />
        </v-col>

        <v-col md="4">
          <v-select
            v-model="topic"
            label="Tópico"
            :items="topics"
            :disabled="!subject || busy"
          />
        </v-col>

        <v-col cols="4">
          <v-btn
            :disabled="disabledBtn"
            depressed
            @click="filter"
          >
            <v-icon left>
              mdi-magnify
            </v-icon>
            Filtrar
          </v-btn>
        </v-col>
      </v-row>

      <div class="py-1">
        <template v-if="loading">
          <Load :loading-msg="'Carregando'" />
        </template>
        <div v-else>
          <div
            v-for="question in questions"
            :key="question.id"
          >
            <div class="my-8">
              <div class="grey lighten-5 px-5 py-3 rounded">
                <router-link :to="{ name: 'conteudos.questoes.id', params: { id: question.id } }">
                  Questão #{{ question.id }}
                </router-link>
                <span
                  v-if="question.intranet_user"
                  class="ml-3"
                >
                  Criada por {{ question.intranet_user.name }} {{ dayjs(question.created_at).fromNow() }}
                </span>
              </div>
              <div class="px-5">
                <editor-content
                  v-if="question.editor"
                  :editor="question.editor"
                  class="editor-content mt-5"
                />
                <div
                  v-else
                  class="red--text"
                >
                  Conteúdo corrompido! Apague esta questão e cadastre-a novamente.
                </div>
              </div>
            </div>

            <v-divider />
          </div>
        </div>
      </div>

      <v-pagination
        v-model="page"
        :length="totalPages"
        :total-visible="5"
        class="mt-4"
      />
    </template>
    <template v-else>
      usuário sem permissão
    </template>
  </ItemPage>
</template>

<script>
import Load from '@/components/Loading.vue'
import utils from '@/api/utils.js'
import ItemPage from '@/components/pages/ItemPage.vue'
import { Editor, EditorContent } from 'tiptap'
import {
  HardBreak,
  Bold,
  Italic,
  History
} from 'tiptap-extensions'
import Image from '@/components/contents/questions/editor/nodes/Image'
import Katex from '@/components/contents/questions/editor/nodes/Katex'
import dayjs from '@/dayjs'

export default {
  components: {
    Load,
    EditorContent,
    ItemPage
  },

  data: () => ({
    loading: true,
    questionsView: false,
    questionsCreate: false,
    disabledBtn: true,

    // Questions
    questions: [],

    // Pagination
    page: 1,
    totalPages: 1,

    // Filters
    teachingSystems: [],
    teachingSystem: null,
    gradeLevelGroupsAndLevels: [],
    gradeLevel: null,
    subjects: [],
    subject: null,
    topics: [],
    topic: null
  }),

  computed: {
    actions () {
      return [
        {
          label: 'Criar Questão',
          action: this.createNewQuestion,
          icon: 'mdi-plus',
          disabled: !this.questionsCreate
        }
      ]
    }
  },
  watch: {
    page (value) {
      this.fetchPage(value)
    },

    teachingSystem (system) {
      if (this.subject) {
        this.fetchTopics(this.gradeLevel, this.subject, system)
      }
    },

    gradeLevel (level) {
      this.subject = null
      this.disabledBtn = true
      this.fetchSubjects(level, this.teachingSystem)
    },

    subject (subject) {
      if (subject === null) {
        this.disabledBtn = true
      } else {
        this.topic = null
        this.fetchTopics(this.gradeLevel, subject, this.teachingSystem)
      }
    }
  },

  async created () {
    this.fetchPage(1)
    this.fetchGradeLevelGroups()
    this.fetchTeachingSystems()
  },

  methods: {
    async fetchPage (page) {
      this.questionsView = utils.checkAnyOrOwn('questions.view-any', 'questions.view-own')
      this.questionsCreate = utils.checkPermissions('questions.create')
      this.loading = true

      const query = [`page=${page}`, 'with=intranetUser']

      if (this.topic) {
        query.push(`topic_id=${this.topic}`)
      } else if (this.subject) {
        query.push(`subject_id=${this.subject}`)
        query.push(`grade_level_id=${this.gradeLevel}`)
      }

      if (!this.topic && this.teachingSystem) {
        query.push(`teaching_system_id=${this.teachingSystem}`)
      }

      const data = await this.$api.listQuestions(query.join('&'))

      for (const question of data.data) {
        try {
          question.editor = new Editor({
            editable: false,
            extensions: [
              new HardBreak(),
              new Bold(),
              new Image(),
              new Italic(),
              new History(),
              new Katex()
            ],
            content: JSON.parse(question.text_data).statement
          })
        } catch (e) {
          question.editor = null
        }
      }

      if (this.questions.length > 0) {
        for (const question of this.questions) {
          question.editor && question.editor.destroy()
        }
      }

      this.questions = data.data
      this.totalPages = data.meta.last_page

      this.loading = false
    },
    createNewQuestion () {
      this.$router.push({ name: 'conteudos.questoes.criar' })
    },

    // Filters
    async fetchTeachingSystems () {
      this.loading = true
      const data = await this.$api.getTeachingSystems()
      this.teachingSystems = [
        { id: null, name: 'Escolha um sistema de ensino' },
        ...data
      ]
      this.loading = false
    },

    async fetchGradeLevelGroups () {
      this.busy = true
      const data = await this.$api.getGradeLevelGroups()
      const levels = data.map((item) => {
        return item.grade_levels.map((level) => {
          return {
            text: item.name + ' - ' + level.name,
            value: level.id
          }
        })
      })
      this.gradeLevelGroupsAndLevels = [
        { value: null, text: 'Escolha um segmento e ano' },
        ...levels.flatMap((item) => item)
      ]
      this.busy = false
    },

    async fetchSubjects (level, system) {
      this.busy = true
      const data = await this.$api.getSubjectsOfGradeLevel(level, system)
      this.subjects = [
        { value: null, text: 'Escolha uma matéria' },
        ...data.map(l => ({ value: l.id, text: l.name }))
      ]
      this.busy = false
    },

    async fetchTopics (level, subject, system = null) {
      this.busy = true
      this.disabledBtn = false
      const data = await this.$api.getTopics(level, subject, system, true)
      this.topics = [
        { value: null, text: 'Qualquer tópico' },
        ...data.map(t => {
          const rt = {
            value: t.id,
            text: t.name
          }

          if (t.teaching_system) {
            rt.text = `${t.name} (${t.teaching_system.short_name})`
          }

          return rt
        })
      ]
      this.busy = false
    },

    async filter () {
      this.busy = true
      this.fetchPage(this.page)
      this.busy = false
    },

    dayjs
  }
}
</script>
