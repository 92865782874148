<template>
  <ItemPage
    title="Artigo"
    size="sm"
    :actions="actions"
  >
    <div class="d-flex flex-row align-center pb-10">
      <div class="d-flex flex-fill flex-column">
        <div class="font-weight-bold">
          Capa do artigo
        </div>
        <div class="text-body-2 text--secondary mt-1">
          A capa será a imagem de exibição da lista de artigos.
        </div>
      </div>
      <div
        class="ml-auto"
        cols="auto"
      >
        <v-btn
          color="primary"
          text
          @click="openUrl(article.cover_path)"
        >
          <v-icon left>
            mdi-cloud
          </v-icon> abrir capa
        </v-btn>
      </div>
    </div>
    <div class="d-flex flex-row align-center pb-10">
      <div class="d-flex flex-fill flex-column">
        <div class="font-weight-bold">
          Artigo
        </div>
        <div class="text-body-2 text--secondary mt-1">
          Envie o artigo no formato (*.zip) e aguarde sua publicação.
        </div>
      </div>
      <div
        class="ml-auto"
        cols="auto"
      >
        <v-btn
          color="primary"
          text
          @click="openUrl(article.path)"
        >
          <v-icon left>
            mdi-cloud
          </v-icon> abrir Artigo
        </v-btn>
      </div>
    </div>

    <v-form ref="form">
      <v-text-field
        v-model="article.title"
        label="Titulo"
        placeholder="Insira o titulo do artigo que será enviado"
        outlined
        @input="checkUpdate"
      />

      <v-text-field
        v-model="article.author"
        label="Autor"
        placeholder="Insira o nome do autor do artigo"
        outlined
        @input="checkUpdate"
      />

      <v-textarea
        v-model="article.description"
        counter
        label="Descrição"
        placeholder="Insira uma breve introdução ao conteudo do artigo"
        outlined
        @input="checkUpdate"
      />
    </v-form>

    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
    >
      {{ msgSnackbar }}

      <template #action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-model="dialogConfirm"
      width="500"
      persistent
    >
      <v-card>
        <DialogConfirm
          title="o Artigo"
          :name="`${article.title}`"
          @close="dialogConfirm = false"
          @confirm="deleteArticle()"
        />
      </v-card>
    </v-dialog>
  </ItemPage>
</template>

<script>
import utils from '@/api/utils.js'
import DialogConfirm from '@/components/Dialog_confirm.vue'
import ItemPage from '@/components/pages/ItemPage.vue'

export default {
  components: {
    DialogConfirm,
    ItemPage
  },
  data () {
    return {
      rulesName: [
        value => !!value || 'Preenchimento obrigatório.'
      ],
      dialogConfirm: false,
      btnUpdateDisabled: true,
      snackbar: false,
      msgSnackbar: '',
      timeout: 3000,
      formError: {},
      article: {},
      currentArticle: {},
      type: null,
      errors: [],
      academicArticlesDelete: false,
      academicArticlesUpdate: false
    }
  },
  computed: {
    id () {
      return this.$route.params.id
    },

    actions () {
      return [
        {
          label: 'Atualizar',
          action: this.updateArticle,
          icon: 'mdi-floppy',
          disabled: this.btnUpdateDisabled
        },
        {
          label: 'Deletar',
          action: () => { this.dialogConfirm = true },
          icon: 'mdi-delete',
          disabled: !this.academicArticlesDelete
        }
      ]
    }
  },
  async created () {
    await this.getArticle()
    this.academicArticlesUpdate = utils.checkPermissions('academic-articles.update-any')
    this.academicArticlesDelete = utils.checkPermissions('academic-articles.delete')
  },
  methods: {

    getErrors (data) {
      if (data.data.errors) {
        this.errors = utils.setErrorsData(data.data.errors)
      } else {
        throw (data)
      }
    },
    validate () {
      return this.$refs.form.validate()
    },
    openUrl (url) {
      window.open(url)
    },
    checkUpdate () {
      if (!this.academicArticlesUpdate) {
        return false
      }
      const data = utils.diffObjects(this.article, this.currentArticle)
      if (utils.size(data) > 0) {
        this.btnUpdateDisabled = false
      } else {
        this.btnUpdateDisabled = true
      }
      return data
    },

    async getArticle () {
      try {
        const response = await this.$api.getArticle(this.id)
        this.article = response
        this.currentArticle = utils.currentData(response)
      } catch (e) {
        // console.log(e.response)
        this.getErrors(e.response)
      }
    },
    async updateArticle () {
      const data = utils.newData(this.article, this.currentArticle)
      try {
        await this.$api.updateArticle(this.id, data)
        this.snackbar = true
        this.msgSnackbar = 'Artigo atualizado com sucesso'
      } catch (e) {
        // console.log(e.response)
        this.getErrors(e.response)
      }
    },
    async deleteArticle () {
      try {
        await this.$api.deleteArticle(this.id)
        this.snackbar = true
        this.msgSnackbar = 'Artigo apagado com sucesso'
        this.$router.push({ name: 'conteudos.artigos' })
      } catch (e) {
        // console.log(e.response)
        this.getErrors(e.response)
      }
    }
  }
}
</script>
