<template>
  <ItemPage
    title="Materiais"
    size="md"
    :actions="actions"
  >
    <template
      v-if="subjectTextBooksView"
    >
      <FilterParameteres
        @setParameters="parameters = $event"
      />
      <v-simple-table
        fixed-header
      >
        <template v-if="loading">
          <Load :loadingmsg="loadingMsg" />
        </template>
        <div
          v-else
          class="mb-5"
        >
          <v-data-table
            :headers="headers"
            :items="contents"
            :items-per-page="15"
            hide-default-footer
            item-key="id"
            disable-sort
            @click:row="editMaterial($event)"
          >
            <!-- eslint-disable-next-line -->
            <template v-slot:item.status="{ item }">
              {{ statusName(item.status) }}
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.tudojunto="{ item }">
              {{ getNameTeachingSystem(item.teaching_system_id) }} <br> {{ getNameGradeLevel(item.grade_level_id) }}
            </template>
          </v-data-table>
        </div>
      </v-simple-table>
      <v-pagination
        v-model="page"
        :length="totalPages"
        :total-visible="5"
        class="mt-4"
      />
    </template>
    <template v-else>
      usuário sem permissão
    </template>
  </ItemPage>
</template>

<script>
import Load from '@/components/Loading.vue'
import FilterParameteres from '@/components/contents/FilterParameters.vue'
import utils from '@/api/utils.js'
import ItemPage from '@/components/pages/ItemPage.vue'
import { getTextForDisplay as statusName } from '@/enums/materialStatus'

export default {
  components: {
    Load,
    FilterParameteres,
    ItemPage
  },
  data () {
    return {
      loading: false,
      loadingMsg: '',
      request: false,
      dialogMaterial: false,
      renderComponent: 0,
      contents: [],
      search: '',
      parameters: '',
      gradelevelGroups: [],
      systems: [],
      hierarchy: [],
      headers: [
        {
          text: 'ID',
          align: 'start',
          value: 'id'
        },
        {
          text: 'Nome',
          align: 'start',
          value: 'name'
        },
        {
          text: '',
          align: 'center',
          value: 'tudojunto'
        },
        {
          text: 'Status',
          align: 'center',
          value: 'status'
        }
      ],

      // Pagination
      page: 1,
      totalPages: 1
    }
  },

  computed: {
    actions () {
      return [
        {
          label: 'Adicionar material',
          action: this.newMaterial,
          icon: 'mdi-home-city',
          disabled: !this.subjectTextBooksCreate
        }
      ]
    },
    subjectTextBooksView () {
      return utils.checkPermissions('subject-textbooks.view-any')
    },
    subjectTextBooksCreate () {
      return utils.checkPermissions('subject-textbooks.create')
    }
  },
  watch: {
    page (value) {
      this.listSubjectTextBook(value)
    },
    parameters () {
      this.page = 1
      this.listSubjectTextBook(this.page)
    }
  },
  async created () {
    this.loading = true
    if (this.subjectTextBooksView) {
      await Promise.all([
        this.getTeachingSystems(),
        this.getGradeLevelGroups(),
        this.listSubjectTextBook(this.page)
      ])
    }
    this.loading = false
  },
  methods: {
    async listSubjectTextBook (page) {
      let content = []
      try {
        if (this.parameters.lenght === 0) {
          content = await this.$api.GetMaterialTextBooks(page)
        } else {
          content = await this.$api.GetMaterialTextBooks(page, this.parameters)
        }
        this.contents = content.data
        this.totalPages = content.meta.last_page
      } catch {
        this.$store.dispatch('setSnackBar', { text: 'Ocorreu um erro ao listar materiais.', color: 'red' })
      }
    },

    async getTeachingSystems () {
      try {
        const response = await this.$api.getTeachingSystems()
        this.systems = response
      } catch (e) {
        this.$store.dispatch('setSnackBar', { text: 'Ocorreu um erro ao obter sistemas de ensino.', color: 'red' })
      }
    },
    async getGradeLevelGroups () {
      try {
        const response = await this.$api.getGradeLevelGroups()
        this.gradelevelGroups = response
        this.hierarchy = response.flatMap((item) => {
          const levelswithnames = item.grade_levels.map((itemLevel) => {
            const levels = {
              name: item.short_name ? item.short_name + ' - ' + itemLevel.name : item.name + ' - ' + itemLevel.name,
              id: itemLevel.id
            }
            return levels
          })
          return levelswithnames
        })
      } catch (e) {
        this.$store.dispatch('setSnackBar', { text: 'Ocorreu um erro ao obter segmentos.', color: 'red' })
      }
    },
    getNameGradeLevel (id) {
      return this.hierarchy.find((item) => item.id === id).name
    },
    getNameTeachingSystem (id) {
      return this.systems.find((item) => item.id === id).name
    },
    editMaterial (event) {
      this.$router.push({ name: 'conteudos.materiais.id', params: { id: event.id } })
    },
    newMaterial () {
      this.$router.push({ name: 'conteudos.materiais.novo' })
    },
    statusName
  }
}
</script>
