<template>
  <ItemPage
    :title="topic.name ? topic.name + '  - Conteúdos' : ''"
    size="md"
  >
    <template v-if="topicsView">
      <v-simple-table fixed-header>
        <template v-if="loading">
          <Load :loadingmsg="loadingMsg" />
        </template>
        <v-data-table
          v-else
          :headers="midiaHeaders"
          :items="midiaContents"
          :items-per-page="15"
          hide-default-footer
          item-key="id"
        >
          <template #top>
            <h2 class="ml-10">
              Midia
            </h2>
          </template>
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template #item.name="{ item }">
            <router-link :to="{ name: 'conteudos.midias.id', params: { id: item.id } }">
              {{ item.name }}
            </router-link>
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.type="{ item }">
            {{ typeText(item.type) }}
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.created_at="{ item }">
            <span
              class="d-inline-block text-truncate"
              style="max-width: 200px;"
            >
              {{ dayjs(item.created_at).format('DD/MM/YYYY') }}
            </span>
          </template>
        </v-data-table>
      </v-simple-table>
      <list-questions
        :topic="parseInt(topicID)"
      />
    </template>
    <template v-else>
      usuário sem permissão
    </template>
  </ItemPage>
</template>

<script>
import Load from '@/components/Loading.vue'
import utils from '@/api/utils.js'
import { getTextForDisplay } from '@/enums/volumeTypes'
import dayjs from '@/dayjs'
import ListQuestions from '@/components/contents/questions/List_Questions.vue'
import ItemPage from '@/components/pages/ItemPage.vue'

export default {
  components: {
    Load,
    ListQuestions,
    ItemPage
  },
  data () {
    return {
      topicsView: false,
      loading: false,
      loadingSave: false,
      loadingMsg: '',
      topic: {},
      midiaContents: [],
      midiaPage: 1,
      midiaTotalPages: null,
      midiaHeaders: [
        {
          text: 'Nome',
          align: 'start',
          value: 'name'
        },
        {
          text: 'Data de Criação',
          align: 'start',
          value: 'created_at'
        },
        {
          text: 'Tipo',
          align: 'start',
          value: 'type'
        }
      ]
    }
  },
  computed: {
    teachingSystemID () { return this.$route.params.teachingSystemID },
    gradeLevelGroupID () { return this.$route.params.gradeLevelGroupID },
    gradeLevelID () { return this.$route.params.gradeLevelID },
    subjectID () { return this.$route.params.subjectID },
    volumeID () { return this.$route.params.volumeID },
    topicID () { return this.$route.params.topicID },
    midiaParameters () {
      const parameters = '&teaching_system_id=' + this.teachingSystemID + '&grade_level_id=' + this.gradeLevelID +
        '&volume_id=' + this.volumeID + '&subject_id=' + this.subjectID + '&topic_id=' + this.topicID
      return parameters
    }
  },
  created () {
    this.fetchAll()
  },
  methods: {
    getTextForDisplay,
    async fetchAll () {
      this.topicsView = utils.checkPermissions('topics.view-any')
      await this.getTopic()
      await this.getContent()
    },
    async getTopic () {
      try {
        this.topic = await this.$api.getTopic(this.topicID)
      } catch {
        // console.log('Erro ao obter a tópico')
      }
    },
    async getContent (page) {
      this.loading = true
      try {
        const response = await this.$api.getMediaContents(this.midiaPage, this.midiaParameters)
        this.midiaContents = response.data
        this.midiaTotalPages = response.meta.last_page
      } catch {
        // console.log('Erro ao listar materiais')
      }
      this.loading = false
    },
    typeText (typevalue) {
      const types = utils.contentType()
      return types.find(element => element.value === typevalue).typeName
    },
    dayjs
  }
}
</script>
