<template>
  <ItemPage
    title="Sistemas de Ensino"
    size="md"
  >
    <template
      v-if="teachingSystemView"
    >
      <v-simple-table fixed-header>
        <template v-if="loading">
          <Load :loadingmsg="loadingMsg" />
        </template>
        <div
          v-else
        >
          <v-data-table
            :headers="headers"
            :items="contents"
            hide-default-footer
            item-key="id"
          >
            <template #item.name="{ item }">
              <router-link :to="{ name: 'conteudos-v2.sistema-de-ensino.teachingSystemID.segmento', params: { teachingSystemID: item.id } }">
                {{ item.name }}
              </router-link>
            </template>
          </v-data-table>
        </div>
      </v-simple-table>
    </template>
    <template v-else>
      usuário sem permissão
    </template>
  </ItemPage>
</template>

<script>
import Load from '@/components/Loading.vue'
import ItemPage from '@/components/pages/ItemPage.vue'
import utils from '@/api/utils.js'

export default {
  components: {
    Load,
    ItemPage
  },
  data () {
    return {
      teachingSystemView: false,
      loading: false,
      loadingMsg: '',
      contents: [],
      headers: [
        {
          text: 'ID',
          align: 'start',
          value: 'id'
        },
        {
          text: 'Nome',
          align: 'start',
          value: 'name'
        },
        {
          text: 'Abreviatura',
          align: 'start',
          value: 'short_name'
        }
      ]
    }
  },
  created () {
    this.fetchAll()
  },
  methods: {
    fetchAll () {
      this.getTeachingSystems()
      this.teachingSystemView = utils.checkPermissions('intranet-users.view-any')
    },
    async getTeachingSystems () {
      this.loading = true
      this.loadingMsg = 'Carregando Lista...'
      try {
        this.contents = await this.$api.getTeachingSystems()
      } catch {
        // console.log('Erro ao listar os sistemas de ensino')
      }
      this.loading = false
    },
    editTeachingSystem (event) {
      this.$router.push({ name: 'conteudos-v2.sistema-de-ensino.teachingSystemID.segmento', params: { teachingSystemID: event.id } })
    }
  }
}
</script>
