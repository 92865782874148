export const types = {
  gl1: 1,
  gl2: 2,
  gl3: 3
}
export const displayNames = {
  [types.gl1]: '1º ano',
  [types.gl2]: '2º ano',
  [types.gl3]: '3º ano'
}
export const realId = {
  [types.gl1]: 14,
  [types.gl2]: 15,
  [types.gl3]: 16
}
export function listTypes () {
  const data = [
    { name: '1º ano', id: 1 },
    { name: '2º ano', id: 2 },
    { name: '3º ano', id: 3 }
  ]
  return data
}
export function getTextForDisplay (type) {
  const name = displayNames[type]

  if (name === undefined) {
    return ''
  }

  return name
}
export function getRealId (fakeId) {
  return realId[fakeId]
}

export default {
  listTypes,
  getTextForDisplay
}
