// import { Node, Plugin } from 'tiptap'
import { Node } from 'tiptap'
import katex from 'katex'

export default class Latex extends Node {
  constructor (name, parent, uploadFunc = null) {
    super(name, parent)
    this.uploadFunc = uploadFunc
  }

  get name () {
    return 'aude_question_katex'
  }

  get schema () {
    return {
      inline: true,
      attrs: {
        expression: {}
      },
      group: 'inline',
      draggable: false,
      parseDOM: [
        {
          tag: `[data-aude-tiptap-type=${this.name}]`,
          getAttrs: dom => ({
            expression: dom.innerHTML
          })
        }
      ],
      toDOM: (node) => {
        const el = document.createElement('span')
        el.setAttribute('data-aude-tiptap-type', this.name)
        el.setAttribute('data-aude-katex-expression', node.attrs.expression)
        el.classList.add('aude_question_katex')

        const innerEl = document.createElement('span')
        el.appendChild(innerEl)
        katex.render(node.attrs.expression, innerEl)

        return el
      }
    }
  }

  commands ({ type }) {
    return {
      aude_question_katex_createNode: attrs => {
        return (state, dispatch) => {
          const { selection } = state
          const position = selection.$cursor ? selection.$cursor.pos : selection.$to.pos
          const transaction = state.tr.insert(position, type.create(attrs))
          dispatch(transaction)
        }
      },

      aude_question_katex_updateNode: attrs => {
        return (state, dispatch) => {
          const { selection } = state
          const { node, from } = selection

          if (!node) {
            // console.log('behrhrhr behrhrhr')
          }

          const transaction = state.tr.setNodeMarkup(from, null, attrs)
          dispatch(transaction)
        }
      }
    }
  }
}
