<template>
  <ItemPage
    title="Cargos"
    size="sm"
    :actions="actions"
  >
    <v-container v-if="rolesView">
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Pesquisa"
            single-line
            hide-details
          />
        </v-col>
      </v-row>
    </v-container>
    <v-simple-table
      v-if="rolesView"
      fixed-header
    >
      <template v-if="loading">
        <Load :loading-msg="loadingMsg" />
      </template>
      <div
        v-else
        class="mr-5 ml-5"
      >
        <v-data-table
          :headers="headers"
          :items="roles"
          :items-per-page="10"
          :search="search"
          item-key="id"
          @click:row="permissions($event)"
        />
      </div>
    </v-simple-table>
    <template v-else>
      usuário sem permissão
    </template>
    <v-dialog
      v-model="dialogAddRole"
      width="500"
      persistent
    >
      <v-card>
        <template v-if="request">
          <Load :loadingmsg="loadingMsg" />
        </template>
        <v-card-text v-else>
          <AddRoleDialog
            :key="renderComponent"
            @close="dialogAddRole = false"
            @save="newRole($event)"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
    >
      {{ msgSnackbar }}
      <template #action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </ItemPage>
</template>

<script>
import AddRoleDialog from './AddRoleDialog.vue'
import ItemPage from '@/components/pages/ItemPage.vue'
import Load from '@/components/Loading.vue'
import utils from '@/api/utils.js'

export default {
  components: {
    AddRoleDialog, Load, ItemPage
  },
  data () {
    return {
      rolesCreate: true,
      rolesView: true,
      updateRole: true,
      loading: false,
      request: false,
      loadingMsg: '',
      roles: [],
      dialogAddRole: false,
      renderComponent: 0,
      snackbar: false,
      msgSnackbar: '',
      timeout: 4000,
      search: '',
      headers: [
        {
          text: 'Nome',
          align: 'start',
          value: 'display_name'
        },
        {
          text: 'Código',
          align: 'start',
          value: 'name'
        }
      ]
    }
  },
  computed: {
    actions () {
      return [
        {
          label: 'Adicionar Cargo',
          action: this.addnewRole,
          icon: 'mdi-plus',
          disabled: !this.rolesCreate
        }
      ]
    }
  },
  created () {
    this.fetchAll()
  },
  methods: {
    fetchAll () {
      this.getRoles()
      this.rolesCreate = utils.checkPermissions('roles.create')
      this.rolesView = utils.checkPermissions('roles.view-any')
    },
    checkPermission (permission) {
      return utils.checkPermissions(permission)
    },
    async getRoles () {
      this.loading = true
      this.loadingMsg = 'Carregando Lista...'
      try {
        const roles = await this.$api.getRoles()
        this.roles = roles
      } catch {
        // console.log('Erro ao listar cargos')
      }
      this.loading = false
    },
    permissions (event) {
      this.$router.push({ name: 'cargos.id', params: { id: event.id } })
    },
    addnewRole (value) {
      this.renderComponent += 1
      this.dialogAddRole = true
    },
    async newRole (data) {
      this.request = true
      this.loadingMsg = 'Salvando...'
      try {
        await this.$api.newRole(data)
        this.request = false
        this.dialogAddRole = false
        this.snackbar = true
        this.msgSnackbar = 'Salvo com sucesso'
        this.fetchAll()
      } catch (e) {
        this.snackbar = true
        this.msgSnackbar = 'Não foi possível salvar, tente novamente mais tarde'
        // console.log(e)
      }
      this.request = false
    }
  }
}
</script>
