<template>
  <v-container
    class="fill-height"
    fluid
  >
    <v-row
      class="text-center"
      justify="center"
    >
      <v-col
        v-if="sessionExp"
        cols="12"
      >
        <v-alert type="warning">
          {{ msgError }}
        </v-alert>
      </v-col>
      <template v-if="loading">
        <v-card
          class="elevation-4 mx-auto"
          min-width="300"
          max-width="800"
        >
          <v-col cols="12">
            <Load :loading-msg="loadingMsg" />
          </v-col>
        </v-card>
      </template>
      <template v-else>
        <v-card
          class="elevation-4 mx-auto pa-6"
          min-width="300"
          max-width="800"
        >
          <div>
            <div>
              <svg
                viewBox="0 0 1010 384"
                xmlns="http://www.w3.org/2000/svg"
                stroke-miterlimit="2"
                height="50"
                style="margin-top:10px;"
              >
                <g fill-rule="nonzero">
                  <path
                    d="M261.119 133.548v128.06h-64.03v-64.03H69.03v64.03H5v-128.06c0-35.323 12.512-65.497 37.537-90.522C67.562 18.002 97.736 5.489 133.059 5.489c35.323 0 65.497 12.513 90.522 37.537 25.025 25.025 37.538 55.199 37.538 90.522zm-64.03 0c0-17.714-6.243-32.815-18.729-45.301-12.485-12.485-27.586-18.728-45.301-18.728-17.714 0-32.815 6.243-45.301 18.728-12.485 12.486-18.728 27.587-18.728 45.301h128.059zM347.131 5.489v128.059c0 17.715 6.243 32.816 18.729 45.302 12.486 12.485 27.586 18.728 45.301 18.728h64.03V5.489h64.029v256.119H411.161c-35.323 0-65.497-12.513-90.522-37.537-25.025-25.025-37.537-55.199-37.537-90.523V5.489h64.029z"
                    fill="#ffd30a"
                  />
                  <path
                    d="M666.839 69.519H602.81v128.059h64.029c17.715 0 32.816-6.243 45.301-18.728 12.486-12.486 18.729-27.587 18.729-45.302 0-17.714-6.243-32.815-18.729-45.301-12.485-12.485-27.586-18.728-45.301-18.728z"
                    fill="#606263"
                  />
                  <path
                    d="M894.335 5.489H1005v64.03H894.335c-17.715 0-32.815 6.243-45.301 18.728-4.161 4.162-7.63 8.591-10.405 13.287H940.97v64.029H838.629c2.775 4.696 6.244 9.125 10.405 13.287 12.486 12.485 27.586 18.728 45.301 18.728H1005v64.03H894.335l-.594-.001c-61.863-.002-157.202-.007-201.946-.022a1.098 1.098 0 01-.183-2.181c24.872-4.595 46.789-16.373 65.749-35.333 25.025-25.025 37.538-55.199 37.538-90.523 0-35.323-12.513-65.497-37.538-90.522-18.96-18.96-40.877-30.738-65.753-35.311a1.12 1.12 0 01.187-2.226h202.54z"
                    fill="#606263"
                  />
                </g>
              </svg>
            </div>
            <div
              class="text-h4"
              style="color: #606263;"
            >
              Intranet
            </div>
          </div>
          <div class="mt-6">
            <v-text-field
              id="login"
              ref="login"
              v-model="login"
              prepend-icon="mdi-account"
              label="E-mail"
              @keydown.enter="$refs.password.focus()"
            />
          </div>
          <div class="mt-6">
            <v-text-field
              ref="password"
              v-model="password"
              prepend-icon="mdi-lock"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              name="input-10-1"
              label="senha"
              @click:append="showPassword = !showPassword"
              @keydown.enter="loginIntranet"
            />
          </div>
          <div class="mt-6">
            <v-btn
              block
              color="primary"
              @click="loginIntranet()"
            >
              Entrar
            </v-btn>
          </div>
        </v-card>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import Load from '@/components/Loading.vue'
export default {
  layout: 'auth',

  components: {
    Load
  },

  data () {
    return {
      loading: false,
      msgError: '',
      loadingMsg: '',
      login: '',
      showPassword: false,
      password: '',
      sessionExp: false
    }
  },
  created () {
    this.verifyLogin()
  },

  mounted () {
    this.$refs.login.focus()
  },

  methods: {
    async loginIntranet () {
      this.loading = true
      this.loadingMsg = 'Verificando usuário e senha'
      const data = {
        email: this.login,
        password: this.password
      }
      try {
        await this.$api.getCookie(data)
      } catch {
      }
      this.getError()
      this.$router.push({ name: 'painel' })
      this.loading = false
    },
    getError () {
      this.msgError = localStorage.getItem('Error')
      this.sessionExp = true
    },
    verifysession () {
      if (this.$store.state.error.status === true) {
        this.msgError = this.$store.state.error.msg
        this.sessionExp = true
      }
    },
    async verifyLogin () {
      this.verifysession()
      if (this.$store.state.intranetUser) {
        this.$router.push({ name: 'painel' })
      }
      // const request = await this.$api.me()
      // this.loading = false
      // if (request === true) {
      //   this.$router.push({ name: 'painel' })
      // }
    }
  }
}
</script>
