<template>
  <ItemPage
    title="Novo Artigo"
    size="sm"
    :actions="actions"
  >
    <div class="d-flex flex-row align-center pb-10">
      <div class="d-flex flex-fill flex-column">
        <div class="font-weight-bold">
          Capa do artigo
        </div>
        <div class="text-body-2 text--secondary mt-1">
          A imagem de capa deverá ser nas dimensões 840x360 e tipos (*.jpg, *.png)
        </div>
      </div>
      <UploadCoverArticle
        :key="renderUpCoverMaterial"
        @beginUpload="uploadMaterial"
        @upload="setCoverMaterialFile($event)"
        @uploadFail="waitingUpload = true"
      />
    </div>
    <div class="d-flex flex-row align-center pb-10">
      <div class="d-flex flex-fill flex-column">
        <div class="font-weight-bold">
          Artigo
        </div>
        <div class="text-body-2 text--secondary mt-1">
          Envie o artigo no formato (*.zip) e aguarde sua publicação.
        </div>
      </div>
      <UploadArticle
        :key="renderUpMaterial"
        @beginUpload="uploadMaterial"
        @upload="setMaterialFile($event)"
        @uploadFail="waitingUpload = true"
      />
    </div>

    <v-form ref="form">
      <v-text-field
        v-model="title"
        label="Titulo"
        placeholder="Insira o titulo do artigo que será enviado"
        :error-messages="errors.title"
        :disabled="!academicArticlesCreate"
        outlined
      />
      <v-text-field
        v-model="author"
        label="Autor"
        placeholder="Insira o nome do autor do artigo"
        outlined
        :error-messages="errors.description"
        :disabled="!academicArticlesCreate"
      />

      <v-textarea
        v-model="description"
        counter
        label="Descrição"
        placeholder="Insira uma breve introdução ao conteudo do artigo"
        outlined
        :error-messages="errors.description"
        :disabled="!academicArticlesCreate"
      />
    </v-form>

    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
    >
      {{ msgSnackbar }}

      <template #action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </ItemPage>
</template>

<script>
import utils from '@/api/utils.js'
import UploadArticle from '@/components/settings/articles/UploadArticle.vue'
import UploadCoverArticle from '@/components/settings/articles/UploadCoverArticle.vue'
import ItemPage from '@/components/pages/ItemPage.vue'

export default {
  components: {
    UploadArticle,
    UploadCoverArticle,
    ItemPage
  },
  data () {
    return {
      waitEnable: true,
      academicArticlesCreate: false,
      loading: false,
      modalListOrNew: false,
      btnDisable: true,
      rulesName: [
        value => !!value || 'Preenchimento obrigatório.'
      ],
      snackbar: false,
      msgSnackbar: '',
      timeout: 3000,
      formError: {},

      title: '',
      author: '',
      description: '',
      temporary_upload_file: null,
      cover_temporary_upload_file: null,
      type: null,
      gradelevels: [],
      files: [],
      errors: [],
      renderUpMaterial: 0,
      renderUpCoverMaterial: 0
    }
  },
  computed: {
    actions () {
      return [
        {
          label: 'Salvar',
          action: this.newArticle,
          icon: 'mdi-floppy',
          disabled: this.btnDisable
        }
      ]
    }
  },
  created () {
    this.academicArticlesCreate = utils.checkPermissions('academic-articles.create')
  },
  methods: {

    getErrors (data) {
      if (data.data.errors) {
        this.errors = utils.setErrorsData(data.data.errors)
      } else {
        throw (data)
      }
    },
    checkSavePermission () {
      if (this.temporary_upload_file && this.cover_temporary_upload_file) {
        this.btnDisable = false
      } else {
        this.btnDisable = true
      }
    },
    setMaterialFile (data) {
      this.temporary_upload_file = data.id
      this.checkSavePermission()
    },
    setCoverMaterialFile (data) {
      this.cover_temporary_upload_file = data.id
      this.checkSavePermission()
    },
    validate () {
      return this.$refs.form.validate()
    },
    sizes (data) {
      return utils.size(data)
    },
    uploadMaterial () {
      this.waitEnable = false
      this.waitingUploadCover = false
    },

    async newArticle () {
      this.loading = true
      this.waitEnable = true
      const data = {
        temporary_upload_file_id: this.temporary_upload_file,
        cover_temporary_upload_file_id: this.cover_temporary_upload_file,
        title: this.title,
        description: this.description,
        author: this.author
      }
      try {
        await this.$api.NewArticle(data)
        this.snackbar = true
        this.msgSnackbar = 'Artigo cadastrado com sucesso'
        this.$router.push({ name: 'conteudos.artigos' })
      } catch (e) {
        this.loading = false
        this.waitEnable = false
        // console.log(e.response)
        this.getErrors(e.response)
      }
      this.loading = false
      this.waitEnable = false
    }
  }
}
</script>
