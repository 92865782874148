export const types = {
  b1: 1,
  b2: 2,
  b3: 3,
  b4: 4
}
export const displayNames = {
  [types.b1]: '1º bimestre',
  [types.b2]: '2º bimestre',
  [types.b3]: '3º bimestre',
  [types.b4]: '4º bimestre'
}
export function listTypes () {
  const data = [
    { name: '1º bimestre', id: 1 },
    { name: '2º bimestre', id: 2 },
    { name: '3º bimestre', id: 3 },
    { name: '4º bimestre', id: 4 }
  ]
  return data
}
export function getTextForDisplay (type) {
  const name = displayNames[type]

  if (name === undefined) {
    return ''
  }

  return name
}

export default {
  listTypes,
  getTextForDisplay
}
