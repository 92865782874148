<template>
  <v-dialog
    width="500"
    persistent
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-card class="px-4 py-4">
      <v-card-title>
        Nova Avaliação
      </v-card-title>
      <v-progress-linear
        v-if="loading"
        color="amber"
        indeterminate
      />
      <v-form
        ref="form"
      >
        <v-text-field
          v-model="name"
          prepend-icon="mdi-note-text"
          hint="Uso interno, não será exibido para as instituições"
          persistent-hint
          :error-messages="formErrors.name"
          :rules="rulesName"
          :disabled="loading"
          label="Nome da avaliação"
          @input="formErrors.name = ''"
        />
        <v-menu
          ref="menuDate"
          v-model="menuDate"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              label="Data da avaliação"
              prepend-icon="mdi-calendar"
              :rules="rulesDate"
              :error-messages="formErrors.starts_at ? formErrors.starts_at : formErrors.ends_at ? formErrors.ends_at : null"
              readonly
              :disabled="loading"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="date"
            :disabled="loading"
            :min="tomorrow"
            no-title
            scrollable
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="menuDate = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.menuDate.save(date)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
        <v-menu
          ref="menuTimeStart"
          v-model="menuTimeStart"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="timeStart"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="timeStart"
              label="Horário para inicio da avaliação"
              prepend-icon="mdi-clock-time-four-outline"
              :rules="rulesTimeStart"
              :error-messages="formErrors.starts_at"
              readonly
              :disabled="loading"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-time-picker
            v-if="menuTimeStart"
            v-model="timeStart"
            format="24hr"
            :max="timeEnd"
            full-width
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="menuTimeStart = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.menuTimeStart.save(timeStart)"
            >
              OK
            </v-btn>
          </v-time-picker>
        </v-menu>
        <v-menu
          ref="menuTimeEnd"
          v-model="menuTimeEnd"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="timeEnd"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="timeEnd"
              label="Horário para o fim da avaliação"
              prepend-icon="mdi-clock-time-four-outline"
              :rules="rulesTimeEnd"
              :error-messages="formErrors.ends_at"
              readonly
              :disabled="loading"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-time-picker
            v-if="menuTimeEnd"
            v-model="timeEnd"
            format="24hr"
            :min="timeStart"
            full-width
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="menuTimeEnd = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.menuTimeEnd.save(timeEnd)"
            >
              OK
            </v-btn>
          </v-time-picker>
        </v-menu>
        <v-select
          v-model="gradeLevel"
          :disabled="loading"
          :items="gradeLevels"
          :rules="rulesGradeLevel"
          prepend-icon="mdi-school"
          label="Ano do Ensino Médio"
          item-text="name"
          item-value="id"
        />
        <!-- volume = periodo ? -->
        <v-select
          v-model="bimester"
          :items="bimesters"
          :rules="rulesBimesters"
          prepend-icon="mdi-calendar"
          item-text="name"
          item-value="id"
          label="Bimestre"
        />
        <v-autocomplete
          v-model="selectedInstitutions"
          :items="institutions"
          prepend-icon="mdi-home-city-outline"
          label="Instituições"
          item-text="code"
          item-value="id"
          multiple
        >
          <template #item="{ item }">
            {{ item.code }} - {{ item.name }}
          </template>
          <template #selection="{ item, select }">
            <v-chip
              small
              close
              @click="select"
              @click:close="selectedInstitutions.splice(selectedInstitutions.indexOf(item.id), 1)"
            >
              {{ item.code }} - {{ item.name }}
            </v-chip>
          </template>
        </v-autocomplete>
      </v-form>
      <div class="d-flex justify-space-between">
        <v-btn
          color="primary"
          text
          @click="close()"
        >
          fechar
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="save()"
        >
          Salvar
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import handlesServerValidation from '@/mixins/handles-server-validation.js'
import { listTypes as listVolumeTypes } from '@/enums/volumeAudeExam'
import { listTypes as listGradeLevelTypes } from '@/enums/gradeLevelAudeExam'
import dayjs from '@/dayjs.js'

export default {
  components: {
  },

  mixins: [
    handlesServerValidation
  ],

  data: () => ({
    rulesName: [
      value => !!value || 'Nome é obrigatório.',
      value => (value && value.length >= 4) || 'Min 4 caracteres',
      value => (value && value.length <= 250) || 'Max 250 caracteres'
    ],
    rulesTimeStart: [
      value => !!value || 'Horário inicial é obrigatório.'
    ],
    rulesTimeEnd: [
      value => !!value || 'Horário final é obrigatório.'
    ],
    rulesGradeLevel: [
      value => !!value || 'Escolher um ano é obrigatório.'
    ],
    rulesDate: [
      value => !!value || 'Escolher uma data é obrigatório.'
    ],
    rulesBimesters: [
      value => !!value || 'Escolher um bimestre é obrigatório.'
    ],
    name: null,
    date: null,
    menuDate: false,
    timeStart: null,
    menuTimeStart: false,
    timeEnd: null,
    menuTimeEnd: false,
    formErrors: {},
    tomorrow: new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().substr(0, 10),
    loading: false,
    gradeLevel: null,
    gradeLevels: [],
    bimester: null,
    bimesters: [],
    selectedInstitutions: [],
    institutions: []
  }),
  async created () {
    this.loading = true
    this.bimesters = listVolumeTypes()
    this.gradeLevels = listGradeLevelTypes()
    this.institutions = await this.$api.getSchool()
    this.loading = false
  },
  methods: {

    listVolumeTypes,
    listGradeLevelTypes,

    validate () {
      return this.$refs.form.validate()
    },
    close () {
      this.$refs.form.reset()
      this.$emit('close')
    },

    async save () {
      const validate = this.validate()
      if (validate === false) {
        return false
      }
      this.loading = true
      const dateStart = this.date + ' ' + this.timeStart + ':00'
      const dateEnd = this.date + ' ' + this.timeEnd + ':00'
      try {
        const data = {
          name: this.name,
          starts_at: dayjs(dateStart).utc().format('YYYY-MM-DD HH:mm:ss'),
          ends_at: dayjs(dateEnd).utc().format('YYYY-MM-DD HH:mm:ss'),
          grade_level_id: this.gradeLevel,
          bimester: this.bimester,
          institutions_ids: this.selectedInstitutions
        }
        await this.$api.NewAudeExam(data)
        this.$store.dispatch('setSnackBar', { text: 'Avaliação criada com sucesso.' })
        this.$emit('reload')
        this.close()
      } catch (e) {
        this.loading = false
        this.$store.dispatch('setSnackBar', { text: 'Ocorreu um erro ao tentar criar a avaliação.', color: 'red' })
        this.getErrors(e)
      }
      this.loading = false
    },
    dayjs
  }
}
</script>
