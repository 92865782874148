<template>
  <ItemPage
    title="Artigos Acadêmicos"
    size="md"
    :actions="actions"
  >
    <v-simple-table
      v-if="academicArticlesView"
      fixed-header
    >
      <template v-if="loading">
        <Load :loadingmsg="loadingMsg" />
      </template>
      <div v-else>
        <v-data-table
          :headers="headers"
          :items="contents"
          :items-per-page="15"
          hide-default-footer
          :search="search"
          item-key="id"
          @click:row="editMaterial($event)"
        >
          <template #item.status="{ item }">
            {{ statusName(item.status) }}
          </template>
        </v-data-table>
      </div>
    </v-simple-table>
    <v-pagination
      v-if="academicArticlesView"
      v-model="page"
      :length="totalPages"
      :total-visible="5"
      class="mt-4"
    />
    <template v-else>
      usuário sem permissão
    </template>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
    >
      {{ msgSnackbar }}

      <template #action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </ItemPage>
</template>

<script>
import Load from '@/components/Loading.vue'
import utils from '@/api/utils.js'
import { getTextForDisplay as statusName } from '@/enums/materialStatus'
import ItemPage from '@/components/pages/ItemPage.vue'

export default {
  components: {
    Load,
    ItemPage
  },
  data () {
    return {
      academicArticlesView: false,
      academicArticlesCreate: false,
      loading: false,
      loadingMsg: '',
      request: false,
      dialogMaterial: false,
      renderComponent: 0,
      contents: [],
      snackbar: false,
      msgSnackbar: '',
      timeout: 3000,
      search: '',
      headers: [
        {
          text: 'ID',
          align: 'start',
          value: 'id'
        },
        {
          text: 'Nome',
          align: 'start',
          value: 'title'
        },
        {
          text: 'Status',
          align: 'center',
          value: 'status'
        }
      ],

      // Pagination
      page: 1,
      totalPages: 1
    }
  },
  computed: {
    actions () {
      return [
        {
          label: 'Adicionar artigo',
          action: this.newMaterial,
          icon: 'mdi-plus',
          disabled: !this.academicArticlesCreate
        }
      ]
    }
  },

  watch: {
    page (value) {
      this.listSubjectTextBook(value)
    }
  },
  created () {
    this.fetchAll()
  },
  methods: {
    fetchAll () {
      this.listSubjectTextBook(this.page)
      this.academicArticlesView = utils.checkPermissions('academic-articles.view-any')
      this.academicArticlesCreate = utils.checkPermissions('academic-articles.create')
    },
    async listSubjectTextBook (page) {
      this.loading = true
      this.loadingMsg = 'Carregando Lista...'
      try {
        const content = await this.$api.ListArticles(page)
        this.contents = content.data
        this.totalPages = content.meta.last_page
      } catch {
        // console.log('Erro ao listar materiais')
      }
      this.loading = false
    },
    editMaterial (event) {
      this.$router.push({ name: 'conteudos.artigos.id', params: { id: event.id } })
    },
    newMaterial () {
      this.$router.push({ name: 'conteudos.artigos.novo' })
    },
    statusName
  }
}
</script>
